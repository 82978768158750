import React from "react";
import logoVideo from "../assets/images/logo.mp4"; // Import the MP4 file

const Loading = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <video 
                src={logoVideo} 
                autoPlay 
                loop 
                muted 
                style={{ width: 200, height: 200 }}
            />
        </div>
    );
};

export default Loading;
