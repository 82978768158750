import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ArrowLeft, Image, Video } from "lucide-react";
import { useSwipeable } from "react-swipeable";
import { getPhotos } from "../services/AdminPhotosService";
import UploadWidget from "../components/UploadWidget";
import "../styles/Photos.css";

const Photos = () => {
    const navigate = useNavigate();
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("photos");
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const photoList = await getPhotos();
                setPhotos(photoList);
                setLoading(false);
            } catch (error) {
                console.error("Failed to load photos:", error);
                setPhotos([]);
                setLoading(false);
            }
        };
        fetchPhotos();
    }, []);

    const filteredMedia = photos.filter((photo) =>
        activeTab === "photos" ? photo.type === "image" : photo.type === "video"
    );

    const openPhotoModal = (photo) => {
        if (photo.type === "image") setSelectedPhoto(photo);
    };

    const closePhotoModal = () => setSelectedPhoto(null);

    const nextPhoto = () => {
        const currentIndex = filteredMedia.findIndex((p) => p.id === selectedPhoto.id);
        const nextIndex = (currentIndex + 1) % filteredMedia.length;
        setSelectedPhoto(filteredMedia[nextIndex]);
    };

    const prevPhoto = () => {
        const currentIndex = filteredMedia.findIndex((p) => p.id === selectedPhoto.id);
        const prevIndex = (currentIndex - 1 + filteredMedia.length) % filteredMedia.length;
        setSelectedPhoto(filteredMedia[prevIndex]);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => nextPhoto(),
        onSwipedRight: () => prevPhoto(),
        trackMouse: true,
        delta: 10,
    });

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const staggerChildren = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
    };

    const handleVideoInteraction = (e) => {
        e.stopPropagation(); // Prevent the parent onClick from triggering
    };

    return (
        <div className="photos-page">
            <header className="photos-header">
                <button className="back-button" onClick={() => navigate(-1)}>
                    <ArrowLeft size={24} /> Back
                </button>
                <motion.div initial="hidden" animate="visible" variants={fadeInUp}>
                    <h2>
                        <Image size={30} className="section-icon" /> Our Photos & Videos
                    </h2>
                </motion.div>
            </header>

            <div className="media-switch">
                <button
                    className={`switch-tab ${activeTab === "photos" ? "active" : ""}`}
                    onClick={() => setActiveTab("photos")}
                >
                    <Image size={18} className="switch-icon" /> Photos
                </button>
                <button
                    className={`switch-tab ${activeTab === "videos" ? "active" : ""}`}
                    onClick={() => setActiveTab("videos")}
                >
                    <Video size={18} className="switch-icon" /> Videos
                </button>
            </div>

            <UploadWidget />
            <section className="photos-section">
                {loading ? (
                    <p className="no-photos">Loading media...</p>
                ) : filteredMedia.length === 0 ? (
                    <p className="no-photos">
                        No {activeTab === "photos" ? "photos" : "videos"} available yet.
                    </p>
                ) : (
                    <motion.div
                        className="photos-grid"
                        initial="hidden"
                        animate="visible"
                        variants={staggerChildren}
                    >
                        {filteredMedia.map((photo) => (
                            <motion.div
                                key={photo.id}
                                className="photo-card"
                                variants={fadeInUp}
                                onClick={() => openPhotoModal(photo)}
                            >
                                <div className="media-wrapper">
                                    {photo.type === "image" ? (
                                        <img src={photo.url} alt="" className="media-content media-image" />
                                    ) : (
                                        <video
                                            controls
                                            playsInline
                                            muted // Add muted to allow autoplay on some mobile devices
                                            className="media-content media-video"
                                            onClick={handleVideoInteraction}
                                            onTouchStart={handleVideoInteraction}
                                            onError={(e) => console.error("Video playback error:", e)}
                                        >
                                            <source src={photo.url} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                    {photo.type === "image" && (
                                        <div className="watermark">DBLOVESTORY</div>
                                    )}
                                </div>
                                {(photo.title || photo.description) && (
                                    <div className="photo-details">
                                        {photo.title && <h3>{photo.title}</h3>}
                                        {photo.description && <p>{photo.description}</p>}
                                    </div>
                                )}
                            </motion.div>
                        ))}
                    </motion.div>
                )}
            </section>
            {selectedPhoto && (
                <div className="photo-modal-overlay" onClick={closePhotoModal}>
                    <div className="photo-modal" onClick={(e) => e.stopPropagation()} {...swipeHandlers}>
                        <div className="modal-image-wrapper">
                            <img src={selectedPhoto.url} alt="" className="modal-image" />
                            <div className="modal-watermark">DBLOVESTORY</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Photos;