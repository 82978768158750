import { Routes, Route } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import Onboarding from "../pages/Onboarding";
import Home from "../pages/Home";
import OurStory from "../pages/OurStory";
import Gift from "../pages/Gift";
import Faq from "../pages/Faq";
import Wish from "../pages/Wish";
import Photos from "../pages/Photos";
import OurDay from "../pages/OurDay";

// Admin Imports
import AdminLogin from "../pages/admin/AdminLogin";
import AdminLayout from "../components/AdminLayout";
import ProtectedRoute from "../routes/ProtectedRoute";
import AdminDashboard from "../pages/admin/AdminDashboard";
import Theme from "../pages/admin/Theme";
import Images from "../pages/admin/Images";
import AdminWishes from "../pages/admin/AdminWishes";
import AdminOurDay from "../pages/admin/AdminOurDay";
import AdminFaq from "../pages/admin/AdminFaq";
import AdminGift from "../pages/admin/AdminGift";
import AdminOurStory from "../pages/admin/AdminOurStory";
import AdminPhotos from "../pages/admin/AdminPhotos";

const AppRoutes = () => {
    return (
        <>
            <ScrollToTop />
            <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Onboarding />} />
                <Route path="/home" element={<Home />} />
                <Route path="/ourStory" element={<OurStory />} />
                <Route path="/gifts" element={<Gift />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/wishes" element={<Wish />} />
                <Route path="/photos" element={<Photos />} />
                <Route path="/ourday" element={<OurDay />} />

                {/* Admin Authentication */}
                <Route path="/admin/login" element={<AdminLogin />} />

                {/* Admin Panel (Protected) */}
                <Route
                    path="/admin/*"
                    element={
                        <ProtectedRoute>
                            <AdminLayout />
                        </ProtectedRoute>
                    }
                >
                    <Route index element={<AdminDashboard />} />
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="theme" element={<Theme />} />
                    <Route path="images" element={<Images />} />
                    <Route path="wishes" element={<AdminWishes />} />
                    <Route path="ourday" element={<AdminOurDay />} />
                    <Route path="faq" element={<AdminFaq />} />
                    <Route path="admingift" element={<AdminGift />} />
                    <Route path="ourstory" element={<AdminOurStory />} />
                    <Route path="photos" element={<AdminPhotos />} />
                </Route>
            </Routes>
        </>
    );
};

export default AppRoutes;
