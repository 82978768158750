import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Gift, Heart, User, CreditCard, Building, Coins, Sparkles, Copy } from "lucide-react";
import { motion, useAnimation } from "framer-motion";
import { toast } from "react-hot-toast";
import { getGiftDetails } from "../services/GiftService";
import "../styles/Gift.css";

const GiftPage = () => {
    const [giftDetails, setGiftDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const sparkleControls = useAnimation();

    useEffect(() => {
        const fetchGiftDetails = async () => {
            setLoading(true);
            const details = await getGiftDetails();
            setGiftDetails(details);
            setLoading(false);
        };

        fetchGiftDetails();
    }, []);

    useEffect(() => {
        // Sparkle animation loop
        sparkleControls.start({
            rotate: [0, 10, -10, 0],
            transition: { repeat: Infinity, duration: 1.5, ease: "easeInOut" },
        });
    }, [sparkleControls]);

    const handleBack = () => {
        navigate(-1);
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Copied to clipboard!");
    };

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const staggerChildren = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
    };

    return (
        <div className="gift-page">
            {/* Header Section */}
            <header className="gift-header">
                <motion.button
                    className="back-button"
                    onClick={handleBack}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <ArrowLeft size={24} /> Back
                </motion.button>
                <motion.div initial="hidden" animate="visible" variants={fadeInUp}>
                    <h2>
                        <Gift size={30} className="section-icon" /> Send Us a Gift
                    </h2>
                </motion.div>
            </header>

            {/* Intro Section */}
            <section className="intro-section">
                <motion.div initial="hidden" animate="visible" variants={fadeInUp}>
                    <p className="intro-message">
                        <Heart size={24} className="section-icon" /> Your presence at our wedding is the greatest gift
                        of all. However, if you wish to bless us with a monetary gift, please find our account details
                        below. We truly appreciate your love and generosity!
                    </p>
                </motion.div>
            </section>

            {/* Gift Details Section */}
            <section className="gift-details-section">
                {loading ? (
                    <motion.p
                        className="no-details"
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                    >
                        Loading gift details...
                    </motion.p>
                ) : giftDetails.length === 0 ? (
                    <motion.p
                        className="no-details"
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                    >
                        No account details are available at the moment. Please check back later.
                    </motion.p>
                ) : (
                    <motion.div
                        className="gift-details"
                        initial="hidden"
                        animate="visible"
                        variants={staggerChildren}
                    >
                        {giftDetails.map((detail) => (
                            <motion.div
                                key={detail.id}
                                className="gift-detail-card"
                                variants={fadeInUp}
                                whileHover={{ y: -5, boxShadow: "0 4px 15px rgba(0, 0, 0, 0.15)" }}
                            >
                                <div className="gift-detail-item">
                                    <User size={20} className="detail-icon" />
                                    <p>
                                        <strong>Account Name:</strong> {detail.accountName}
                                    </p>
                                </div>
                                <div className="gift-detail-item">
                                    <CreditCard size={20} className="detail-icon" />
                                    <p>
                                        <strong>Account Number:</strong> {detail.accountNumber}
                                        <button
                                            className="copy-btn"
                                            onClick={() => handleCopy(detail.accountNumber)}
                                        >
                                            <Copy size={16} />
                                        </button>
                                    </p>
                                </div>
                                <div className="gift-detail-item">
                                    <Building size={20} className="detail-icon" />
                                    <p>
                                        <strong>Bank Name:</strong> {detail.bankName}
                                    </p>
                                </div>
                                <div className="gift-detail-item">
                                    <Coins size={20} className="detail-icon" />
                                    <p>
                                        <strong>Currency:</strong> {detail.currency}
                                    </p>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                )}
            </section>

            {/* Thank You Section */}
            <section className="thank-you-section">
                <motion.p
                    className="thank-you"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                >
                    <motion.span animate={sparkleControls}>
                        <Sparkles size={24} className="section-icon" />
                    </motion.span>{" "}
                    Thank you for being part of our special day. We’re so grateful for your support and love!
                </motion.p>
            </section>
        </div>
    );
};

export default GiftPage;