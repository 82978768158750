import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Trash2, Edit } from "lucide-react";
import { toast } from "react-hot-toast";
import { getPhotos, deletePhoto, addPhoto } from "../services/AdminPhotosService";
import "../styles/AdminPhotosManage.css";

const AdminPhotosManage = () => {
    const [photos, setPhotos] = useState([]);
    const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
    const [editModal, setEditModal] = useState({ open: false, photo: null });
    const [sortBy, setSortBy] = useState("timestamp"); // Default sort by timestamp

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const photoList = await getPhotos();
                setPhotos(photoList || []);
            } catch (error) {
                toast.error("Failed to load photos.");
                setPhotos([]);
            }
        };
        fetchPhotos();
    }, []);

    const handleDelete = async () => {
        try {
            await deletePhoto(deleteModal.id);
            setPhotos((prev) => prev.filter((photo) => photo.id !== deleteModal.id));
            setDeleteModal({ open: false, id: null });
            toast.success("Media deleted successfully!");
        } catch (error) {
            toast.error("Failed to delete media.");
        }
    };

    const openDeleteModal = (id) => {
        setDeleteModal({ open: true, id });
    };

    const closeDeleteModal = () => {
        setDeleteModal({ open: false, id: null });
    };

    const openEditModal = (photo) => {
        setEditModal({ open: true, photo });
    };

    const closeEditModal = () => {
        setEditModal({ open: false, photo: null });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const updatedPhoto = {
            ...editModal.photo,
            title: e.target.title.value || "Untitled",
            description: e.target.description.value || "",
        };

        try {
            await deletePhoto(updatedPhoto.id);
            const newId = await addPhoto(updatedPhoto);
            setPhotos((prev) =>
                prev.map((p) => (p.id === updatedPhoto.id ? { ...updatedPhoto, id: newId } : p))
            );
            closeEditModal();
            toast.success("Media updated successfully!");
        } catch (error) {
            toast.error("Failed to update media.");
        }
    };

    const sortPhotos = (photos) => {
        if (sortBy === "type") {
            const images = photos.filter((photo) => photo.type === "image");
            const videos = photos.filter((photo) => photo.type === "video");
            return [...videos, ...images]; // Videos first, then images
        }
        return [...photos].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Default: newest first
    };

    return (
        <div className="admin-photos-manage">
            <h3>Manage Media</h3>
            <div className="sort-controls">
                <label>Sort by: </label>
                <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                    <option value="timestamp">Date (Newest First)</option>
                    <option value="type">Type (Videos First)</option>
                </select>
            </div>
            <div className="photos-list">
                {photos.length === 0 ? (
                    <p>No photos or videos uploaded yet.</p>
                ) : (
                    sortPhotos(photos).map((photo) => (
                        <motion.div
                            key={photo.id}
                            className="photo-item"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            {photo.type === "image" ? (
                                <img src={photo.url} alt={photo.title} className="media-preview" />
                            ) : (
                                <video controls className="media-preview">
                                    <source src={photo.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                            <div className="photo-info">
                                <h4>{photo.title}</h4>
                                <p>{photo.description || "No description"}</p>
                                <div className="action-buttons">
                                    <button onClick={() => openEditModal(photo)}>
                                        <Edit size={20} /> Edit
                                    </button>
                                    <button onClick={() => openDeleteModal(photo.id)}>
                                        <Trash2 size={20} /> Delete
                                    </button>
                                </div>
                            </div>
                        </motion.div>
                    ))
                )}
            </div>

            {deleteModal.open && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Confirm Deletion</h3>
                        <p>Are you sure you want to delete this media?</p>
                        <div className="modal-buttons">
                            <button className="modal-cancel" onClick={closeDeleteModal}>
                                Cancel
                            </button>
                            <button className="modal-confirm" onClick={handleDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {editModal.open && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Edit Media</h3>
                        <form onSubmit={handleEditSubmit}>
                            <div className="form-group">
                                <label>Title:</label>
                                <input
                                    type="text"
                                    name="title"
                                    defaultValue={editModal.photo?.title || ""}
                                    placeholder="Enter title (optional)"
                                />
                            </div>
                            <div className="form-group">
                                <label>Description:</label>
                                <textarea
                                    name="description"
                                    defaultValue={editModal.photo?.description || ""}
                                    placeholder="Enter description (optional)"
                                />
                            </div>
                            <div className="modal-buttons">
                                <button type="button" className="modal-cancel" onClick={closeEditModal}>
                                    Cancel
                                </button>
                                <button type="submit" className="modal-confirm">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminPhotosManage;