import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/sw.js").then((registration) => {
      console.log("Service Worker Registered");

      registration.addEventListener("updatefound", () => {
          const newWorker = registration.installing;
          newWorker.onstatechange = () => {
              if (newWorker.state === "installed") {
                  if (navigator.serviceWorker.controller) {
                      console.log("New update available!");
                      newWorker.postMessage("update-sw");
                  }
              }
          };
      });
  }).catch((error) => console.error("SW registration failed:", error));
}

reportWebVitals();
