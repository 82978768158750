// src/context/ColorContext.js
import { createContext, useState, useContext, useEffect } from "react";
import ThemeService from "../services/ThemeService";

const ColorContext = createContext();

const predefinedThemes = {
    default: {
        primary: "#6B456A",
        secondary: "#E8B5C4",
        background: "#FDF8F5",
        text: "#9E5330",
        accent: "#CDA875",
        icons: "9E5330",
    },
    dark: {
        primary: "#4A2C4B",
        secondary: "#C68B9A",
        background: "#E5D9D2",
        text: "#1A1F2A",
        accent: "#B5925A",
    },
    ocean: {
        primary: "#2A5D77",
        secondary: "#A3C9D9",
        background: "#F5F9FA",
        text: "#1C2526",
        accent: "#D4A017",
    },
    forest: {
        primary: "#355E3B",
        secondary: "#A9CBA4",
        background: "#F5F5F0",
        text: "#2A2A2A",
        accent: "#D4A017",
    },
};

export const ColorProvider = ({ children }) => {
    const [colors, setColors] = useState(predefinedThemes.default);
    const [selectedTheme, setSelectedTheme] = useState("default");
    const [customThemes, setCustomThemes] = useState({});

    useEffect(() => {
        const fetchColors = async () => {
            try {
                const data = await ThemeService.fetchThemeData();
                setSelectedTheme(data.selectedTheme);
                setColors(data.colors);
                setCustomThemes(data.customThemes || {});
            } catch (error) {
                console.error("Error initializing theme:", error);
                setColors(predefinedThemes.default);
                setSelectedTheme("default");
                setCustomThemes({});
            }
        };
        fetchColors();
    }, []);

    const updateColors = async (themeName, customColors = null) => {
        try {
            let newColors = customColors;
            if (!newColors) {
                const allThemes = await ThemeService.getAllThemes(predefinedThemes);
                newColors = allThemes[themeName];
            }
            
            if (!newColors) {
                console.warn(`Theme ${themeName} not found`);
                return;
            }

            setSelectedTheme(themeName);
            setColors(newColors);
            await ThemeService.updateThemeData(themeName, newColors, customThemes);
        } catch (error) {
            console.error("Error updating colors:", error);
        }
    };

    const addCustomTheme = async (themeName, customColors) => {
        try {
            const data = await ThemeService.addCustomTheme(themeName, customColors);
            setCustomThemes(data.customThemes);
            setSelectedTheme(data.selectedTheme);
            setColors(data.colors);
        } catch (error) {
            console.error("Error adding custom theme:", error);
            throw error;
        }
    };

    const editCustomTheme = async (oldThemeName, newThemeName, customColors) => {
        if (!oldThemeName.startsWith("custom-")) {
            throw new Error("Cannot edit predefined themes");
        }
        const newCustomThemeName = `custom-${newThemeName.toLowerCase().replace(/\s+/g, "-")}`;
        const updatedCustomThemes = { ...customThemes };
        delete updatedCustomThemes[oldThemeName];
        updatedCustomThemes[newCustomThemeName] = customColors;
        
        setCustomThemes(updatedCustomThemes);
        if (selectedTheme === oldThemeName) {
            setSelectedTheme(newCustomThemeName);
            setColors(customColors);
        }
        await ThemeService.updateThemeData(selectedTheme === oldThemeName ? newCustomThemeName : selectedTheme, colors, updatedCustomThemes);
    };

    const deleteCustomTheme = async (themeName) => {
        if (!themeName.startsWith("custom-")) {
            throw new Error("Cannot delete predefined themes");
        }
        const updatedCustomThemes = { ...customThemes };
        delete updatedCustomThemes[themeName];
        
        setCustomThemes(updatedCustomThemes);
        if (selectedTheme === themeName) {
            setSelectedTheme("default");
            setColors(predefinedThemes.default);
            await ThemeService.updateThemeData("default", predefinedThemes.default, updatedCustomThemes);
        } else {
            await ThemeService.updateThemeData(selectedTheme, colors, updatedCustomThemes);
        }
    };

    return (
        <ColorContext.Provider
            value={{
                colors,
                updateColors,
                selectedTheme,
                predefinedThemes,
                customThemes,
                addCustomTheme,
                editCustomTheme,
                deleteCustomTheme,
            }}
        >
            {children}
        </ColorContext.Provider>
    );
};

export const useColors = () => {
    const context = useContext(ColorContext);
    if (context === undefined) {
        throw new Error('useColors must be used within a ColorProvider');
    }
    return context;
};