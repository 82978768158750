import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ArrowLeft, Heart, Send } from "lucide-react";
import { toast } from "react-hot-toast";
import { openDB } from "idb";
import { collection, addDoc, getDocs, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";
import "../styles/Wish.css";

// IndexedDB setup
const DB_NAME = "WishDB";
const STORE_NAME = "wishes";

const Wish = () => {
    const navigate = useNavigate();
    const [wishes, setWishes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({ name: "", wish: "" });
    const [userId, setUserId] = useState(null);
    const [likedWishes, setLikedWishes] = useState(new Set());

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const staggerChildren = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
    };

    useEffect(() => {
        let storedUserId = localStorage.getItem("userId");
        if (!storedUserId) {
            storedUserId = `user-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
            localStorage.setItem("userId", storedUserId);
        }
        setUserId(storedUserId);

        const savedLikes = JSON.parse(localStorage.getItem("likedWishes") || "[]");
        setLikedWishes(new Set(savedLikes));
    }, []);

    const initDB = async () => {
        return await openDB(DB_NAME, 1, {
            upgrade(db) {
                if (!db.objectStoreNames.contains(STORE_NAME)) {
                    db.createObjectStore(STORE_NAME, { keyPath: "id" });
                }
            },
        });
    };

    useEffect(() => {
        const fetchWishes = async () => {
            const db = await initDB();
            const cachedWishes = await db.getAll(STORE_NAME);

            if (cachedWishes.length > 0) {
                setWishes(cachedWishes);
                setLoading(false);
            }

            try {
                const snapshot = await getDocs(collection(firestore, "wishes"));
                const fetchedWishes = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    name: doc.data().name,
                    wish: doc.data().wish,
                    likes: doc.data().likes || [],
                    timestamp: doc.data().timestamp,
                }));
                setWishes(fetchedWishes);
                setLoading(false);

                const tx = db.transaction(STORE_NAME, "readwrite");
                const store = tx.objectStore(STORE_NAME);
                await store.clear();
                fetchedWishes.forEach((wish) => store.put(wish));
                await tx.done;
            } catch (error) {
                console.error("Error fetching wishes:", error);
                setLoading(false);
            }
        };

        fetchWishes();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.name.trim()) {
            toast.error("Please provide your name.");
            return;
        }
        if (!formData.wish.trim()) {
            toast.error("Please enter your wish.");
            return;
        }

        const newWish = {
            name: formData.name.trim(),
            wish: formData.wish.trim(),
            likes: [],
            timestamp: new Date().toISOString(),
        };

        try {
            const docRef = await addDoc(collection(firestore, "wishes"), newWish);
            const updatedWish = { ...newWish, id: docRef.id };
            setWishes((prev) => [...prev, updatedWish]);

            const db = await initDB();
            await db.put(STORE_NAME, updatedWish);

            setFormData({ name: "", wish: "" });
            setModalOpen(false);
            toast.success("Wish submitted successfully!");
        } catch (error) {
            console.error("Error submitting wish:", error);
            toast.error("Failed to submit wish.");
        }
    };

    const handleLike = async (wishId, currentLikes) => {
        if (!userId) return;

        const newLikes = [...currentLikes];
        const userIndex = newLikes.indexOf(userId);
        let updatedLikes;

        if (likedWishes.has(wishId)) {
            // Unlike
            if (userIndex !== -1) {
                newLikes.splice(userIndex, 1);
            }
            updatedLikes = newLikes;
            setLikedWishes((prev) => {
                const newSet = new Set(prev);
                newSet.delete(wishId);
                localStorage.setItem("likedWishes", JSON.stringify([...newSet]));
                return newSet;
            });
        } else {
            // Like
            newLikes.push(userId);
            updatedLikes = newLikes;
            setLikedWishes((prev) => {
                const newSet = new Set(prev);
                newSet.add(wishId);
                localStorage.setItem("likedWishes", JSON.stringify([...newSet]));
                return newSet;
            });
        }

        // Update local state
        setWishes((prev) =>
            prev.map((wish) => (wish.id === wishId ? { ...wish, likes: updatedLikes } : wish))
        );

        // Update Firestore
        try {
            const wishDoc = doc(firestore, "wishes", wishId);
            await updateDoc(wishDoc, { likes: updatedLikes });

            // Update IndexedDB
            const db = await initDB();
            const updatedWish = wishes.find((wish) => wish.id === wishId);
            if (updatedWish) {
                await db.put(STORE_NAME, { ...updatedWish, likes: updatedLikes });
            }
        } catch (error) {
            console.error("Error updating likes:", error);
            toast.error("Failed to update like.");
        }
    };

    return (
        <div className="wish-page">
            <header className="wish-header">
                <button className="back-button" onClick={() => navigate(-1)}>
                    <ArrowLeft size={24} /> Back
                </button>
                <motion.div initial="hidden" animate="visible" variants={fadeInUp}>
                    <h2>
                        <Heart size={30} className="section-icon" /> Wishes for the Couple
                    </h2>
                </motion.div>
            </header>

            <motion.button
                className="submit-wish-btn"
                onClick={() => setModalOpen(true)}
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
            >
                <Heart size={20} /> Submit a Wish
            </motion.button>

            <section className="wishes-section">
                {loading ? (
                    <p className="no-wishes">Loading wishes...</p>
                ) : wishes.length === 0 ? (
                    <p className="no-wishes">No wishes yet. Be the first!</p>
                ) : (
                    <motion.div className="wishes-list" variants={staggerChildren} initial="hidden" animate="visible">
                        {wishes.map((wish) => (
                            <motion.div key={wish.id} className="wish-card" variants={fadeInUp}>
                                <div className="wish-icon-wrapper">
                                    <Heart size={24} className="wish-icon" />
                                </div>
                                <div className="wish-content">
                                    <p className="wish-text">"{wish.wish}"</p>
                                    <p className="wish-name">— {wish.name}</p>
                                    <div className="like-section">
                                        <button
                                            className={`like-button ${likedWishes.has(wish.id) ? "liked" : ""}`}
                                            onClick={() => handleLike(wish.id, wish.likes)}
                                        >
                                            <Heart size={20} />
                                        </button>
                                        <p className="like-count">
                                            {wish.likes.length} like{wish.likes.length !== 1 ? "s" : ""}
                                        </p>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                )}
            </section>

            {modalOpen && (
                <div className="modal-overlay" onClick={() => setModalOpen(false)}>
                    <motion.div
                        className="modal-content"
                        onClick={(e) => e.stopPropagation()}
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        transition={{ duration: 0.3 }}
                    >
                        <h3>Submit Your Wish</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Your Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="wish">Your Wish</label>
                                <textarea
                                    id="wish"
                                    name="wish"
                                    value={formData.wish}
                                    onChange={handleInputChange}
                                    placeholder="Write your wish here"
                                    rows="4"
                                    required
                                />
                            </div>
                            <button type="submit" className="submit-btn">
                                <Send size={20} /> Submit Wish
                            </button>
                        </form>
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default Wish;