import { useState, useEffect } from "react";
import { FaCalendarAlt, FaClock, FaHourglassHalf, FaHeartbeat } from "react-icons/fa";
import "../styles/CountDown.css";

const CountDown = ({ weddingDate }) => {
    // Default wedding date (replace with your actual date)
    const targetDate = weddingDate || new Date("2025-04-12T18:00:00"); // Example: June 15, 2025, 2:00 PM

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

    function calculateTimeLeft(date) {
        const now = new Date();
        const difference = date - now;

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((difference % (1000 * 60)) / 1000),
        };
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000); // Update every second

        return () => clearInterval(timer); // Cleanup on unmount
    }, [targetDate]); // Add targetDate as dependency in case it changes

    const countdownComplete = timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0;

    return (
        <div className="countdown-container">
            {countdownComplete ? (
                <h3 className="countdown-complete">The Big Day is Here!</h3>
            ) : (
                <div className="countdown-grid">
                    <div className="countdown-item">
                        <FaCalendarAlt className="countdown-icon" />
                        <span className="countdown-value">{timeLeft.days}</span>
                        <span className="countdown-label">Days</span>
                    </div>
                    <div className="countdown-item">
                        <FaClock className="countdown-icon" />
                        <span className="countdown-value">{timeLeft.hours}</span>
                        <span className="countdown-label">Hours</span>
                    </div>
                    <div className="countdown-item">
                        <FaHourglassHalf className="countdown-icon" />
                        <span className="countdown-value">{timeLeft.minutes}</span>
                        <span className="countdown-label">Minutes</span>
                    </div>
                    <div className="countdown-item">
                        <FaHeartbeat className="countdown-icon" />
                        <span className="countdown-value">{timeLeft.seconds}</span>
                        <span className="countdown-label">Seconds</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CountDown;