// src/pages/admin/Theme.js
import { useState } from "react";
import { useColors } from "../../contexts/ColorContext";
import { motion } from "framer-motion";
import { Palette, PlusCircle, Edit2, Trash2 } from "lucide-react";
import toast from "react-hot-toast";
import "../../styles/Theme.css";

const Theme = () => {
    const { 
        updateColors, 
        selectedTheme, 
        predefinedThemes, 
        customThemes, 
        addCustomTheme, 
        editCustomTheme, 
        deleteCustomTheme 
    } = useColors();
    
    const [activeTab, setActiveTab] = useState("select");
    const [customThemeName, setCustomThemeName] = useState("");
    const [customColors, setCustomColors] = useState({
        primary: "",
        secondary: "",
        background: "",
        text: "",
        accent: "",
    });
    const [editingTheme, setEditingTheme] = useState(null);

    const handleCustomColorChange = (e) => {
        const { name, value } = e.target;
        const normalizedValue = value.length === 6 && !value.startsWith("#") && /^[0-9A-Fa-f]{6}$/.test(value)
            ? `#${value}`
            : value;
        setCustomColors((prev) => ({ ...prev, [name]: normalizedValue }));
    };

    const handleCreateTheme = async (e) => {
        e.preventDefault();
        if (!customThemeName) {
            toast.error("Please provide a theme name.");
            return;
        }
        if (Object.values(customColors).some((color) => !color || !/^#[0-9A-F]{6}$/i.test(color))) {
            toast.error("Please provide valid hex color codes (e.g., #FF0000 or FFFFFF).");
            return;
        }
        
        try {
            await toast.promise(
                addCustomTheme(customThemeName, customColors),
                {
                    loading: "Saving theme...",
                    success: "Theme created successfully!",
                    error: "Failed to create theme.",
                }
            );
            resetForm();
        } catch (error) {
            console.error("Error creating theme:", error);
        }
    };

    const handleEditTheme = (themeName) => {
        const theme = { ...predefinedThemes, ...customThemes }[themeName];
        setEditingTheme(themeName);
        setCustomThemeName(themeName.replace("custom-", "").replace(/-/g, " "));
        setCustomColors(theme);
        setActiveTab("create");
    };

    const handleUpdateTheme = async (e) => {
        e.preventDefault();
        if (!customThemeName) {
            toast.error("Please provide a theme name.");
            return;
        }
        if (Object.values(customColors).some((color) => !color || !/^#[0-9A-F]{6}$/i.test(color))) {
            toast.error("Please provide valid hex color codes (e.g., #FF0000 or FFFFFF).");
            return;
        }

        try {
            await toast.promise(
                editCustomTheme(editingTheme, customThemeName, customColors),
                {
                    loading: "Updating theme...",
                    success: "Theme updated successfully!",
                    error: "Failed to update theme.",
                }
            );
            resetForm();
        } catch (error) {
            console.error("Error updating theme:", error);
        }
    };

    const handleDeleteTheme = async (themeName) => {
        if (!themeName.startsWith("custom-")) {
            toast.error("Cannot delete predefined themes.");
            return;
        }
        if (window.confirm("Are you sure you want to delete this theme?")) {
            try {
                await toast.promise(
                    deleteCustomTheme(themeName),
                    {
                        loading: "Deleting theme...",
                        success: "Theme deleted successfully!",
                        error: "Failed to delete theme.",
                    }
                );
            } catch (error) {
                console.error("Error deleting theme:", error);
            }
        }
    };

    const resetForm = () => {
        setCustomThemeName("");
        setCustomColors({
            primary: "",
            secondary: "",
            background: "",
            text: "",
            accent: "",
        });
        setEditingTheme(null);
        setActiveTab("select");
    };

    const allThemes = { ...predefinedThemes, ...customThemes };

    return (
        <motion.div
            className="theme-settings"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <h1>Theme Settings 🎨</h1>
            <div className="current-theme">
                Current Theme: <strong>{selectedTheme.startsWith("custom-") 
                    ? selectedTheme.replace("custom-", "").replace(/-/g, " ") 
                    : selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)}</strong>
            </div>

            <div className="mode-toggle">
                <motion.button
                    className={`toggle-btn ${activeTab === "select" ? "active" : ""}`}
                    onClick={() => { setEditingTheme(null); setActiveTab("select"); }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <Palette size={24} />
                    <span>Select Theme</span>
                </motion.button>
                <motion.button
                    className={`toggle-btn ${activeTab === "create" ? "active" : ""}`}
                    onClick={() => setActiveTab("create")}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <PlusCircle size={24} />
                    <span>{editingTheme ? "Edit Theme" : "Create Theme"}</span>
                </motion.button>
            </div>

            <div className="tab-content">
                {activeTab === "select" && (
                    <motion.div
                        className="theme-selector"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className="theme-grid">
                            {Object.keys(allThemes).map((theme) => (
                                <motion.div
                                    key={theme}
                                    className="theme-item"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <motion.button
                                        className={`theme-icon-button ${selectedTheme === theme ? "selected" : ""}`}
                                        onClick={() => updateColors(theme)}
                                    >
                                        <Palette size={32} />
                                        <span>
                                            {theme.startsWith("custom-")
                                                ? theme.replace("custom-", "").replace(/-/g, " ")
                                                : theme.charAt(0).toUpperCase() + theme.slice(1)}
                                        </span>
                                    </motion.button>
                                    <div className="theme-actions">
                                        <button
                                            className="action-btn edit-btn"
                                            onClick={() => handleEditTheme(theme)}
                                            title="Edit Theme"
                                        >
                                            <Edit2 size={16} />
                                        </button>
                                        {theme.startsWith("custom-") && (
                                            <button
                                                className="action-btn delete-btn"
                                                onClick={() => handleDeleteTheme(theme)}
                                                title="Delete Theme"
                                            >
                                                <Trash2 size={16} />
                                            </button>
                                        )}
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>
                )}

                {activeTab === "create" && (
                    <motion.form
                        onSubmit={editingTheme ? handleUpdateTheme : handleCreateTheme}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="theme-form"
                    >
                        <div className="form-grid">
                            <div className="form-group">
                                <label>Theme Name:</label>
                                <input
                                    type="text"
                                    value={customThemeName}
                                    onChange={(e) => setCustomThemeName(e.target.value)}
                                    placeholder="e.g., Sunset"
                                    required
                                    disabled={editingTheme && !editingTheme.startsWith("custom-")}
                                />
                            </div>
                            {Object.keys(customColors).map((color) => (
                                <div key={color} className="form-group">
                                    <label>{color.charAt(0).toUpperCase() + color.slice(1)}:</label>
                                    <input
                                        type="text"
                                        name={color}
                                        value={customColors[color]}
                                        onChange={handleCustomColorChange}
                                        placeholder="FF0000"
                                        pattern="^#[0-9A-Fa-f]{6}$|^[0-9A-Fa-f]{6}$"
                                        title="Please enter a valid 6-digit hex color code (e.g., FF0000 or #FF0000)"
                                    />
                                </div>
                            ))}
                        </div>
                        <motion.button
                            type="submit"
                            className="save-theme-button"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            {editingTheme ? "Update Theme" : "Save Custom Theme"}
                        </motion.button>
                        {editingTheme && (
                            <motion.button
                                type="button"
                                className="cancel-button"
                                onClick={resetForm}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                Cancel
                            </motion.button>
                        )}
                    </motion.form>
                )}
            </div>
        </motion.div>
    );
};

export default Theme;