import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { User, Mail, MessageSquare, Phone, MessageCircle } from "lucide-react";
import { getRSVP, addRSVP } from "../services/AdminOurDayService";
import emailjs from "@emailjs/browser";
import "../styles/RSVP.css";

// Initialize EmailJS with your User ID
emailjs.init("FPpXzom46V7uFtTLa"); // Replace with your EmailJS User ID

const RSVP = () => {
    const [rsvpResponses, setRSVPResponses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [formLoading, setFormLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        const fetchRSVP = async () => {
            try {
                const rsvpData = await getRSVP();
                setRSVPResponses(rsvpData || []);
                setLoading(false);
            } catch (error) {
                console.error("Failed to load RSVP data:", error);
                setLoading(false);
            }
        };
        fetchRSVP();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !email) {
            alert("Please fill in all required fields (Name, Email).");
            return;
        }

        setFormLoading(true);
        try {
            const templateParams = {
                from_name: name,
                from_email: email,
                message: message || "No message provided.",
                to_email: "test@bzzinc.com",
            };

            await emailjs.send(
                "mail.privateemail.com",
                "template_aljzuxq",
                templateParams
            );

            const rsvpData = {
                name,
                email,
                message,
                timestamp: new Date().toISOString(),
            };
            await addRSVP(rsvpData);

            setRSVPResponses((prev) => [...prev, rsvpData]);
            setFormSubmitted(true);
            setName("");
            setEmail("");
            setMessage("");
        } catch (error) {
            console.error("Error sending RSVP:", error);
            alert("Failed to send RSVP. Please try again later.");
        } finally {
            setFormLoading(false);
        }
    };

    const openWhatsApp = () => {
        const phoneNumber = "++2349161821626";
        window.open(`https://wa.me/${phoneNumber}?text=Hello,%20I%20have%20a%20question%20about%20the%20event!`, "_blank");
    };

    const openPhone = () => {
        const phoneNumber = "+23455566892";
        window.open(`tel:${phoneNumber}`, "_blank");
    };

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    return (
        <motion.div
            className="rsvp-details"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
        >
            {loading ? (
                <p className="no-data">Loading...</p>
            ) : (
                <>
                    <h3>RSVP</h3>
                    {formSubmitted ? (
                        <p className="rsvp-success">
                            Thank you for your RSVP! We look forward to seeing you.
                        </p>
                    ) : (
                        <form onSubmit={handleSubmit} className="rsvp-form">
                            <div className="rsvp-form-group">
                                <label htmlFor="name">Name *</label>
                                <div className="input-container">
                                    <User size={20} className="input-icon" />
                                    <input
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Your Name"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="rsvp-form-group">
                                <label htmlFor="email">Email *</label>
                                <div className="input-container">
                                    <Mail size={20} className="input-icon" />
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Your Email"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="rsvp-form-group">
                                <label htmlFor="message">Message (Optional)</label>
                                <div className="input-container">
                                    <MessageSquare size={20} className="input-icon" />
                                    <textarea
                                        id="message"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder="Any special requests or notes?"
                                        rows="4"
                                    />
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="rsvp-submit-button"
                                disabled={formLoading}
                            >
                                {formLoading ? "Submitting..." : "Submit RSVP"}
                            </button>
                        </form>
                    )}

                    <div className="contact-section">
                        <p className="contact-text">Need to reach us?</p>
                        <div className="contact-buttons">
                            <button onClick={openWhatsApp} className="contact-button">
                                <MessageCircle size={24} />
                                <span>WhatsApp</span>
                            </button>
                            <button onClick={openPhone} className="contact-button">
                                <Phone size={24} />
                                <span>Call Us</span>
                            </button>
                        </div>
                    </div>
                </>
            )}
        </motion.div>
    );
};

export default RSVP;