import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../../contexts/AdminContext";
import { Upload, Settings } from "lucide-react";
import AdminPhotosUpload from "../../components/AdminPhotosUpload";
import AdminPhotosManage from "../../components/AdminPhotosManage";
import "../../styles/AdminPhotos.css";

const AdminPhotos = () => {
    const { isAdmin, loading } = useAdmin();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("upload");

    useEffect(() => {
        if (!loading && !isAdmin) {
            navigate("/");
        }
    }, [isAdmin, loading, navigate]);

    return (
        <div className="admin-photos-container">
            <h1>Admin Photos & Videos</h1>
            <div className="tabs">
                <button
                    className={`tab ${activeTab === "upload" ? "active" : ""}`}
                    onClick={() => setActiveTab("upload")}
                >
                    <Upload size={20} /> Upload
                </button>
                <button
                    className={`tab ${activeTab === "manage" ? "active" : ""}`}
                    onClick={() => setActiveTab("manage")}
                >
                    <Settings size={20} /> Manage
                </button>
            </div>
            <div className="tab-content">
                {activeTab === "upload" ? <AdminPhotosUpload /> : <AdminPhotosManage />}
            </div>
        </div>
    );
};

export default AdminPhotos;