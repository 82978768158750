// src/components/HomeButtonIcon.js
import { Link } from "react-router-dom";
import {
    BookOpen,
    CalendarHeart,
    Image,
    HelpCircle,
    MessageCircle,
    Gift,
} from "lucide-react";
import "../styles/HomeButtonIcon.css"; // Updated CSS file name

const icons = {
    story: BookOpen,
    day: CalendarHeart,
    photos: Image,
    faq: HelpCircle,
    wishes: MessageCircle,
    gifts: Gift,
};

const HomeButtonIcon = ({ title, icon, screen }) => {
    const IconComponent = icons[icon] || BookOpen; // Default fallback

    return (
        <Link to={`/${screen}`} className="home-button-icon">
            <IconComponent className="home-icon" />
            <span>{title}</span>
        </Link>
    );
};

export default HomeButtonIcon;