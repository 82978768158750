import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Shirt, Calendar } from "lucide-react";
import { getEvent, getDressCode } from "../services/AdminOurDayService";
import "../styles/Event.css";

const Event = () => {
    const [events, setEvents] = useState([]);
    const [dressCode, setDressCode] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [eventData, dressCodeData] = await Promise.all([
                    getEvent(),
                    getDressCode(),
                ]);
                setEvents(eventData || []);
                setDressCode(dressCodeData);
                setLoading(false);
            } catch (error) {
                console.error("Failed to load data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const staggerChildren = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
    };

    return (
        <div className="event-wrapper">
            {/* Dress Code Section (Fixed at Top) */}
            <motion.div
                className="dress-code-section"
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
            >
                {loading ? (
                    <p className="no-data">Loading...</p>
                ) : dressCode ? (
                    <div className="dress-code-container">
                        <h3>
                            <Shirt size={28} /> Dress Code
                        </h3>
                        <p>{dressCode}</p>
                    </div>
                ) : (
                    <p className="no-data">No dress code specified.</p>
                )}
            </motion.div>

            {/* Event Details Section */}
            <motion.div
                className="event-details"
                initial="hidden"
                animate="visible"
                variants={staggerChildren}
            >
                {loading ? (
                    <p className="no-data">Loading...</p>
                ) : events.length > 0 ? (
                    events
                        .sort((a, b) => new Date(`${a.date}T${a.time}`) - new Date(`${b.date}T${b.time}`))
                        .map((event) => (
                            <motion.div
                                key={event.id}
                                className="event-container"
                                variants={fadeInUp}
                            >
                                <h3>
                                    <Calendar size={28} /> {event.title || "Untitled Event"}
                                </h3>
                                <p className="event-description">{event.description || "No description available."}</p>
                                <div className="event-info">
                                    <p className="event-date">
                                        <strong>Date:</strong> {event.date || "Date not specified."}
                                    </p>
                                    <p className="event-time">
                                        <strong>Time:</strong> {event.time || "Time not specified."}
                                    </p>
                                </div>
                            </motion.div>
                        ))
                ) : (
                    <p className="no-data">No events scheduled yet.</p>
                )}
            </motion.div>
        </div>
    );
};

export default Event;