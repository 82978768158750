import { openDB } from "idb";
import { firestore } from "../config/firebase";
import { collection, onSnapshot, getDocs } from "firebase/firestore";

const DB_NAME = "TawoseDB";
const STORE_NAME = "homeImages";

const initializeDatabase = async () => {
    const db = await openDB(DB_NAME, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: "id" });
            }
        },
    });
    return db;
};

const dbPromise = initializeDatabase();

const storeImagesInIndexedDB = async (images) => {
    const db = await dbPromise;
    const tx = db.transaction(STORE_NAME, "readwrite");
    const store = tx.objectStore(STORE_NAME);
    await store.clear();
    for (const image of images) {
        await store.put(image);
    }
    await tx.done;
};

export const getImagesFromIndexedDB = async () => {
    const db = await dbPromise;
    const tx = db.transaction(STORE_NAME, "readonly");
    const store = tx.objectStore(STORE_NAME);
    const images = await store.getAll();
    if (images.length > 0) {
        console.log("Images source: IndexedDB");
    }
    return images;
};

export const startImagesListener = (callback) => {
    const imagesRef = collection(firestore, "homeImages");
    const unsubscribe = onSnapshot(imagesRef, async (snapshot) => {
        const images = snapshot.docs.map((doc) => ({
            id: doc.id,
            url: doc.data().url,
            title: doc.data().title || "Untitled",
            description: doc.data().description || "No description available.",
        }));
        await storeImagesInIndexedDB(images);
        callback(images);
    }, (error) => {
        callback([]);
    });
    return unsubscribe;
};

export const getHomeImages = async () => {
    try {
        const imagesRef = collection(firestore, "homeImages");
        const snapshot = await getDocs(imagesRef);
        const images = snapshot.docs.map((doc) => ({
            id: doc.id,
            url: doc.data().url,
            title: doc.data().title || "Untitled",
            description: doc.data().description || "No description available.",
        }));
        console.log("Images source: Firestore");
        await storeImagesInIndexedDB(images);
        return images;
    } catch (error) {
        return [];
    }
};