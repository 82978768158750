import { firestore } from "../config/firebase";
import { collection, getDocs, addDoc, deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { openDB } from "idb";

// Firestore collections
const eventCollection = collection(firestore, "ourDay", "event", "details");
const rsvpCollection = collection(firestore, "ourDay", "rsvp", "responses");
const locationCollection = collection(firestore, "ourDay", "location", "details");
const dressCodeDoc = doc(firestore, "ourDay", "dressCode"); 

// IndexedDB setup
const DB_NAME = "OurDayDB";
const EVENT_STORE = "event";
const RSVP_STORE = "rsvp";
const LOCATION_STORE = "location";
const DRESS_CODE_STORE = "dressCode";
const CACHE_VERSION_KEY = "ourDayCacheVersion";

// Initialize IndexedDB
const initDB = async () => {
    return await openDB(DB_NAME, 2, {  // Increment version to 2
        upgrade(db) {
            if (!db.objectStoreNames.contains(EVENT_STORE)) {
                db.createObjectStore(EVENT_STORE, { keyPath: "id" });
            }
            if (!db.objectStoreNames.contains(RSVP_STORE)) {
                db.createObjectStore(RSVP_STORE, { keyPath: "id" });
            }
            if (!db.objectStoreNames.contains(LOCATION_STORE)) {
                db.createObjectStore(LOCATION_STORE, { keyPath: "id" });
            }
            if (!db.objectStoreNames.contains(DRESS_CODE_STORE)) {
                db.createObjectStore(DRESS_CODE_STORE, { keyPath: "id" });
            }
        },
    });
};

// Fetch Event details with caching
export const getEvent = async (forceRefresh = false) => {
    const db = await initDB();
    const cachedVersion = localStorage.getItem(CACHE_VERSION_KEY) || "0";
    const currentVersion = "1";

    if (!forceRefresh && cachedVersion === currentVersion) {
        const cachedEvent = await db.getAll(EVENT_STORE);
        if (cachedEvent.length > 0) {
            console.log("Loaded event from cache:", cachedEvent.length);
            return cachedEvent;
        }
    }

    try {
        const snapshot = await getDocs(eventCollection);
        const eventData = snapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
            description: doc.data().description || "",
            date: doc.data().date,
            time: doc.data().time,
            timestamp: doc.data().timestamp,
        }));

        // Update cache
        const tx = db.transaction(EVENT_STORE, "readwrite");
        const store = tx.objectStore(EVENT_STORE);
        await store.clear();
        eventData.forEach((event) => store.put(event));
        await tx.done;

        localStorage.setItem(CACHE_VERSION_KEY, currentVersion);
        console.log("Fetched and cached event:", eventData.length);
        return eventData;
    } catch (error) {
        console.error("Error fetching event:", error);
        const cachedEvent = await db.getAll(EVENT_STORE);
        if (cachedEvent.length > 0) {
            console.log("Firestore failed, returning cached event:", cachedEvent.length);
            return cachedEvent;
        }
        throw new Error("Failed to fetch event: " + error.message);
    }
};

// Add or Update Event details
export const addEvent = async (eventData) => {
    try {
        const docRef = await addDoc(eventCollection, eventData);
        console.log("Event added with ID:", docRef.id);

        // Update cache
        const db = await initDB();
        const cachedEvent = { ...eventData, id: docRef.id };
        await db.put(EVENT_STORE, cachedEvent);

        return docRef.id;
    } catch (error) {
        console.error("Error adding event:", error);
        throw new Error("Failed to add event: " + error.message);
    }
};

// Delete Event
export const deleteEvent = async (id) => {
    try {
        const eventDoc = doc(firestore, "ourDay", "event", "details", id);
        await deleteDoc(eventDoc);
        console.log("Event deleted with ID:", id);

        // Update cache
        const db = await initDB();
        await db.delete(EVENT_STORE, id);
    } catch (error) {
        console.error("Error deleting event:", error);
        throw new Error("Failed to delete event: " + error.message);
    }
};

// Fetch RSVP responses with caching
export const getRSVP = async (forceRefresh = false) => {
    const db = await initDB();
    const cachedVersion = localStorage.getItem(CACHE_VERSION_KEY) || "0";
    const currentVersion = "1";

    if (!forceRefresh && cachedVersion === currentVersion) {
        const cachedRSVP = await db.getAll(RSVP_STORE);
        if (cachedRSVP.length > 0) {
            console.log("Loaded RSVP from cache:", cachedRSVP.length);
            return cachedRSVP;
        }
    }

    try {
        const snapshot = await getDocs(rsvpCollection);
        const rsvpData = snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            email: doc.data().email,
            message: doc.data().message || "",
            timestamp: doc.data().timestamp,
        }));

        // Update cache
        const tx = db.transaction(RSVP_STORE, "readwrite");
        const store = tx.objectStore(RSVP_STORE);
        await store.clear();
        rsvpData.forEach((rsvp) => store.put(rsvp));
        await tx.done;

        localStorage.setItem(CACHE_VERSION_KEY, currentVersion);
        console.log("Fetched and cached RSVP:", rsvpData.length);
        return rsvpData;
    } catch (error) {
        console.error("Error fetching RSVP:", error);
        const cachedRSVP = await db.getAll(RSVP_STORE);
        if (cachedRSVP.length > 0) {
            console.log("Firestore failed, returning cached RSVP:", cachedRSVP.length);
            return cachedRSVP;
        }
        throw new Error("Failed to fetch RSVP: " + error.message);
    }
};

// Add RSVP response
export const addRSVP = async (rsvpData) => {
    try {
        const docRef = await addDoc(rsvpCollection, rsvpData);
        console.log("RSVP added with ID:", docRef.id);

        // Update cache
        const db = await initDB();
        const cachedRSVP = { ...rsvpData, id: docRef.id };
        await db.put(RSVP_STORE, cachedRSVP);

        return docRef.id;
    } catch (error) {
        console.error("Error adding RSVP:", error);
        throw new Error("Failed to add RSVP: " + error.message);
    }
};

// Delete RSVP response
export const deleteRSVP = async (id) => {
    try {
        const rsvpDoc = doc(firestore, "ourDay", "rsvp", "responses", id);
        await deleteDoc(rsvpDoc);
        console.log("RSVP deleted with ID:", id);

        // Update cache
        const db = await initDB();
        await db.delete(RSVP_STORE, id);
    } catch (error) {
        console.error("Error deleting RSVP:", error);
        throw new Error("Failed to delete RSVP: " + error.message);
    }
};

// Fetch Location details with caching
export const getLocation = async (forceRefresh = false) => {
    const db = await initDB();
    const cachedVersion = localStorage.getItem(CACHE_VERSION_KEY) || "0";
    const currentVersion = "1";

    if (!forceRefresh && cachedVersion === currentVersion) {
        const cachedLocation = await db.getAll(LOCATION_STORE);
        if (cachedLocation.length > 0) {
            console.log("Loaded location from cache:", cachedLocation.length);
            return cachedLocation;
        }
    }

    try {
        const snapshot = await getDocs(locationCollection);
        const locationData = snapshot.docs.map((doc) => ({
            id: doc.id,
            address: doc.data().address,
            venue: doc.data().venue,
            mapUrl: doc.data().mapUrl,
            timestamp: doc.data().timestamp,
        }));

        // Update cache
        const tx = db.transaction(LOCATION_STORE, "readwrite");
        const store = tx.objectStore(LOCATION_STORE);
        await store.clear();
        locationData.forEach((location) => store.put(location));
        await tx.done;

        localStorage.setItem(CACHE_VERSION_KEY, currentVersion);
        console.log("Fetched and cached location:", locationData.length);
        return locationData;
    } catch (error) {
        console.error("Error fetching location:", error);
        const cachedLocation = await db.getAll(LOCATION_STORE);
        if (cachedLocation.length > 0) {
            console.log("Firestore failed, returning cached location:", cachedLocation.length);
            return cachedLocation;
        }
        throw new Error("Failed to fetch location: " + error.message);
    }
};

// Add or Update Location details
export const addLocation = async (locationData) => {
    try {
        const docRef = await addDoc(locationCollection, locationData);
        console.log("Location added with ID:", docRef.id);

        // Update cache
        const db = await initDB();
        const cachedLocation = { ...locationData, id: docRef.id };
        await db.put(LOCATION_STORE, cachedLocation);

        return docRef.id;
    } catch (error) {
        console.error("Error adding location:", error);
        throw new Error("Failed to add location: " + error.message);
    }
};

// Delete Location
export const deleteLocation = async (id) => {
    try {
        const locationDoc = doc(firestore, "ourDay", "location", "details", id);
        await deleteDoc(locationDoc);
        console.log("Location deleted with ID:", id);

        // Update cache
        const db = await initDB();
        await db.delete(LOCATION_STORE, id);
    } catch (error) {
        console.error("Error deleting location:", error);
        throw new Error("Failed to delete location: " + error.message);
    }
};

// Fetch Dress Code with caching
export const getDressCode = async (forceRefresh = false) => {
    const db = await initDB();
    const cachedVersion = localStorage.getItem(CACHE_VERSION_KEY) || "0";
    const currentVersion = "1";

    if (!forceRefresh && cachedVersion === currentVersion) {
        const cachedDressCode = await db.get(DRESS_CODE_STORE, "dressCode");
        if (cachedDressCode) {
            console.log("Loaded dress code from cache:", cachedDressCode);
            return cachedDressCode.value;
        }
    }

    try {
        const docSnap = await getDoc(dressCodeDoc);
        let dressCode = "";
        if (docSnap.exists()) {
            dressCode = docSnap.data().value || "";
        }

        // Update cache
        const db = await initDB();
        await db.put(DRESS_CODE_STORE, { id: "dressCode", value: dressCode });

        localStorage.setItem(CACHE_VERSION_KEY, currentVersion);
        console.log("Fetched and cached dress code:", dressCode);
        return dressCode;
    } catch (error) {
        console.error("Error fetching dress code:", error);
        const cachedDressCode = await db.get(DRESS_CODE_STORE, "dressCode");
        if (cachedDressCode) {
            console.log("Firestore failed, returning cached dress code:", cachedDressCode);
            return cachedDressCode.value;
        }
        throw new Error("Failed to fetch dress code: " + error.message);
    }
};

// Update Dress Code
export const updateDressCode = async (dressCode) => {
    try {
        await setDoc(dressCodeDoc, { value: dressCode });
        console.log("Dress code updated:", dressCode);

        // Update cache
        const db = await initDB();
        await db.put(DRESS_CODE_STORE, { id: "dressCode", value: dressCode });
    } catch (error) {
        console.error("Error updating dress code:", error);
        throw new Error("Failed to update dress code: " + error.message);
    }
};