import { firestore } from "../config/firebase";
import { collection, getDocs, addDoc, deleteDoc, doc } from "firebase/firestore";
import { openDB } from "idb";

const photosCollection = collection(firestore, "photos");
const DB_NAME = "PhotosDB";
const STORE_NAME = "photos";
const CACHE_VERSION_KEY = "photosCacheVersion";

// Initialize IndexedDB
const initDB = async () => {
    return await openDB(DB_NAME, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: "id" });
            }
        },
    });
};

// Fetch photos with caching
export const getPhotos = async (forceRefresh = false) => {
    const db = await initDB();
    const cachedVersion = localStorage.getItem(CACHE_VERSION_KEY) || "0";
    const currentVersion = "1"; // Increment this manually when cache structure changes

    if (!forceRefresh && cachedVersion === currentVersion) {
        const cachedPhotos = await db.getAll(STORE_NAME);
        if (cachedPhotos.length > 0) {
            console.log("Loaded photos from cache:", cachedPhotos.length);
            return cachedPhotos;
        }
    }

    try {
        const snapshot = await getDocs(photosCollection);
        const photos = snapshot.docs.map((doc) => ({
            id: doc.id,
            url: doc.data().url,
            title: doc.data().title,
            description: doc.data().description || "",
            type: doc.data().type, // "image" or "video"
            timestamp: doc.data().timestamp,
        }));

        // Update cache
        const tx = db.transaction(STORE_NAME, "readwrite");
        const store = tx.objectStore(STORE_NAME);
        await store.clear();
        photos.forEach((photo) => store.put(photo));
        await tx.done;

        localStorage.setItem(CACHE_VERSION_KEY, currentVersion);
        console.log("Fetched and cached photos:", photos.length);
        return photos;
    } catch (error) {
        console.error("Error fetching photos:", error);
        // Fallback to cache if Firestore fails
        const cachedPhotos = await db.getAll(STORE_NAME);
        if (cachedPhotos.length > 0) {
            console.log("Firestore failed, returning cached photos:", cachedPhotos.length);
            return cachedPhotos;
        }
        throw new Error("Failed to fetch photos: " + error.message);
    }
};

export const addPhoto = async (photo) => {
    try {
        const docRef = await addDoc(photosCollection, photo);
        console.log("Photo/Video added with ID:", docRef.id);

        // Update cache
        const db = await initDB();
        const cachedPhoto = { ...photo, id: docRef.id };
        await db.put(STORE_NAME, cachedPhoto);

        return docRef.id;
    } catch (error) {
        console.error("Error adding photo/video:", error);
        throw new Error("Failed to add photo/video: " + error.message);
    }
};

export const deletePhoto = async (id) => {
    try {
        const photoDoc = doc(firestore, "photos", id);
        await deleteDoc(photoDoc);
        console.log("Photo/Video deleted with ID:", id);

        // Update cache
        const db = await initDB();
        await db.delete(STORE_NAME, id);
    } catch (error) {
        console.error("Error deleting photo/video:", error);
        throw new Error("Failed to delete photo/video: " + error.message);
    }
};