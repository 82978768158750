// src/components/AdminGift.js
import { useState, useEffect } from "react";
import { firestore } from "../../config/firebase";
import { collection, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { Edit, Trash2, DollarSign, PoundSterling, Banknote, PlusCircle, Settings } from "lucide-react";
import { toast } from "react-hot-toast"; // Import toast
import { getGiftDetails } from "../../services/GiftService"; // Import the fetch function
import "../../styles/AdminGift.css";

const AdminGift = () => {
    const [giftDetails, setGiftDetails] = useState([]);
    const [formData, setFormData] = useState({ accountName: "", accountNumber: "", bankName: "", currency: "USD" });
    const [editId, setEditId] = useState(null);
    const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
    const [isAdding, setIsAdding] = useState(true);

    const currencies = [
        { value: "USD", label: "USD", icon: <DollarSign size={20} /> },
        { value: "GBP", label: "GBP", icon: <PoundSterling size={20} /> },
        { value: "NGN", label: "NGN", icon: <Banknote size={20} /> },
    ];

    // Fetch gift details on mount
    useEffect(() => {
        const fetchGiftDetails = async () => {
            const details = await getGiftDetails();
            setGiftDetails(details);
        };

        fetchGiftDetails();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newDetail = { ...formData };

        try {
            if (editId) {
                await updateDoc(doc(firestore, "giftDetails", editId), newDetail);
                setGiftDetails(giftDetails.map((detail) =>
                    detail.id === editId ? { ...detail, ...newDetail } : detail
                ));
                toast.success("Account updated successfully!");
                setEditId(null);
            } else {
                const docRef = await addDoc(collection(firestore, "giftDetails"), newDetail);
                setGiftDetails([...giftDetails, { id: docRef.id, ...newDetail }]);
                toast.success("Account added successfully!");
            }
            setFormData({ accountName: "", accountNumber: "", bankName: "", currency: "USD" });
        } catch (error) {
            toast.error("Failed to save account details. Please try again.");
            console.error("Error saving account details:", error);
        }
    };

    const handleEdit = (detail) => {
        setFormData({
            accountName: detail.accountName,
            accountNumber: detail.accountNumber,
            bankName: detail.bankName,
            currency: detail.currency,
        });
        setEditId(detail.id);
        setIsAdding(true);
    };

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(firestore, "giftDetails", deleteModal.id));
            setGiftDetails(giftDetails.filter((detail) => detail.id !== deleteModal.id));
            setDeleteModal({ open: false, id: null });
            toast.success("Account deleted successfully!");
        } catch (error) {
            toast.error("Failed to delete account. Please try again.");
            console.error("Error deleting account:", error);
        }
    };

    const openDeleteModal = (id) => {
        setDeleteModal({ open: true, id });
    };

    const closeDeleteModal = () => {
        setDeleteModal({ open: false, id: null });
    };

    return (
        <div className="admin-gift">
            <h2>Gift Registry Management</h2>

            {/* Mode Toggle */}
            <div className="mode-toggle">
                <button
                    className={`toggle-btn ${isAdding ? "active" : ""}`}
                    onClick={() => setIsAdding(true)}
                >
                    <PlusCircle size={20} /> Add Account
                </button>
                <button
                    className={`toggle-btn ${!isAdding ? "active" : ""}`}
                    onClick={() => setIsAdding(false)}
                >
                    <Settings size={20} /> Manage Accounts
                </button>
            </div>

            {/* Add/Edit Form */}
            {isAdding && (
                <form className="gift-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="accountName"
                        value={formData.accountName}
                        onChange={handleInputChange}
                        placeholder="Account Name"
                        required
                    />
                    <input
                        type="text"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleInputChange}
                        placeholder="Account Number"
                        required
                    />
                    <input
                        type="text"
                        name="bankName"
                        value={formData.bankName}
                        onChange={handleInputChange}
                        placeholder="Bank Name"
                        required
                    />
                    <div className="currency-select">
                        <select
                            name="currency"
                            value={formData.currency}
                            onChange={handleInputChange}
                        >
                            {currencies.map((currency) => (
                                <option key={currency.value} value={currency.value}>
                                    {currency.label}
                                </option>
                            ))}
                        </select>
                        <span className="currency-icon">
                            {currencies.find((c) => c.value === formData.currency)?.icon}
                        </span>
                    </div>
                    <button type="submit">{editId ? "Update" : "Add"} Account</button>
                </form>
            )}

            {/* Manage Accounts List */}
            {!isAdding && (
                <div className="gift-list">
                    {giftDetails.length === 0 ? (
                        <p>No gift details added yet.</p>
                    ) : (
                        giftDetails.map((detail) => (
                            <div key={detail.id} className="gift-item">
                                <div className="gift-info">
                                    <p><strong>Name:</strong> {detail.accountName}</p>
                                    <p><strong>Account:</strong> {detail.accountNumber}</p>
                                    <p><strong>Bank:</strong> {detail.bankName}</p>
                                    <p><strong>Currency:</strong> {detail.currency}</p>
                                </div>
                                <div className="gift-actions">
                                    <button onClick={() => handleEdit(detail)}>
                                        <Edit size={20} />
                                    </button>
                                    <button onClick={() => openDeleteModal(detail.id)}>
                                        <Trash2 size={20} />
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {deleteModal.open && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Confirm Deletion</h3>
                        <p>Are you sure you want to delete this account detail?</p>
                        <div className="modal-buttons">
                            <button className="modal-cancel" onClick={closeDeleteModal}>
                                Cancel
                            </button>
                            <button className="modal-confirm" onClick={handleDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminGift;