import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../../contexts/AdminContext";
import { Calendar, UserCheck, MapPin } from "lucide-react";
import AdminRSVP from "../../components/AdminRSVP";
import AdminLocation from "../../components/AdminLocation"; // Updated import
import "../../styles/AdminOurDay.css";
import AdminEvent from "../../components/AdminEvent";

const AdminOurDay = () => {
    const { isAdmin, loading } = useAdmin();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("event");

    useEffect(() => {
        if (!loading && !isAdmin) {
            navigate("/");
        }
    }, [isAdmin, loading, navigate]);

    return (
        <div className="admin-our-day-container">
            <h1>Manage Our Day</h1>
            <div className="tabs">
                <button
                    className={`tab ${activeTab === "event" ? "active" : ""}`}
                    onClick={() => setActiveTab("event")}
                >
                    <Calendar size={20} /> Event
                </button>
                <button
                    className={`tab ${activeTab === "rsvp" ? "active" : ""}`}
                    onClick={() => setActiveTab("rsvp")}
                >
                    <UserCheck size={20} /> RSVP
                </button>
                <button
                    className={`tab ${activeTab === "location" ? "active" : ""}`}
                    onClick={() => setActiveTab("location")}
                >
                    <MapPin size={20} /> Location
                </button>
            </div>
            <div className="tab-content">
                {activeTab === "event" && <AdminEvent />}
                {activeTab === "rsvp" && <AdminRSVP />}
                {activeTab === "location" && <AdminLocation />}
            </div>
        </div>
    );
};

export default AdminOurDay;