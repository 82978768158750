import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./contexts/AuthContext";
import { AdminProvider } from "./contexts/AdminContext";
import { ColorProvider, useColors } from "./contexts/ColorContext";
import { LoadingProvider, useLoading } from "./contexts/LoadingContext";
import { motion } from "framer-motion";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/Routes";
import Loading from "./components/Loading";

function App() {
    return (
        <AuthProvider>
            <AdminProvider>
                <ColorProvider>
                    <LoadingProvider>
                        <AppContent />
                    </LoadingProvider>
                </ColorProvider>
            </AdminProvider>
        </AuthProvider>
    );
}

const AppContent = () => {
    const { loading } = useLoading();
    const { colors } = useColors();

    return (
        <Router>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                    "--primary": colors.primary,
                    "--secondary": colors.secondary,
                    "--background": colors.background,
                    "--text": colors.text,
                }}
            >
                {loading ? <Loading /> : <AppRoutes />}
                <Toaster position="top-center" />
            </motion.div>
        </Router>
    );
};

export default App;
