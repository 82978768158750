import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ArrowLeft, Calendar } from "lucide-react";
import Event from "../components/Event";
import RSVP from "../components/RSVP";
import Location from "../components/Location";
import "../styles/OurDay.css";

const OurDay = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("event");

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    return (
        <div className="our-day-page">
            <header className="our-day-header">
                <button className="back-button" onClick={() => navigate(-1)}>
                    <ArrowLeft size={24} /> Back
                </button>
                <motion.div initial="hidden" animate="visible" variants={fadeInUp}>
                    <h2>
                        <Calendar size={30} className="section-icon" /> Our Day
                    </h2>
                </motion.div>
            </header>

            <div className="media-switch">
                <button
                    className={`switch-tab ${activeTab === "event" ? "active" : ""}`}
                    onClick={() => setActiveTab("event")}
                >
                    Event
                </button>
                <button
                    className={`switch-tab ${activeTab === "rsvp" ? "active" : ""}`}
                    onClick={() => setActiveTab("rsvp")}
                >
                    RSVP
                </button>
                <button
                    className={`switch-tab ${activeTab === "location" ? "active" : ""}`}
                    onClick={() => setActiveTab("location")}
                >
                    Location
                </button>
            </div>

            <section className="our-day-section">
                <div className="tab-content">
                    {activeTab === "event" && <Event />}
                    {activeTab === "rsvp" && <RSVP />}
                    {activeTab === "location" && <Location />}
                </div>
            </section>
        </div>
    );
};

export default OurDay;