import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Edit, Trash2, Shirt, PlusCircle, Calendar } from "lucide-react";
import { getEvent, addEvent, deleteEvent, getDressCode, updateDressCode } from "../services/AdminOurDayService";
import "../styles/AdminEvent.css";

const AdminEvent = () => {
    const [events, setEvents] = useState([]);
    const [dressCode, setDressCode] = useState("");
    const [dressCodeInput, setDressCodeInput] = useState("");
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        time: "",
        title: "",
        description: "",
        date: "",
    });
    const [editingId, setEditingId] = useState(null);
    const [formError, setFormError] = useState("");
    const [dressCodeError, setDressCodeError] = useState("");
    const [mode, setMode] = useState("dressCode"); // "dressCode", "create", or "manage"

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [eventData, dressCodeData] = await Promise.all([getEvent(), getDressCode()]);
                setEvents(eventData || []);
                setDressCode(dressCodeData);
                setDressCodeInput(dressCodeData);
                setLoading(false);
            } catch (error) {
                console.error("Failed to load data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleDressCodeChange = (e) => {
        setDressCodeInput(e.target.value);
    };

    const handleDressCodeSubmit = async (e) => {
        e.preventDefault();
        if (!dressCodeInput.trim()) {
            setDressCodeError("Dress code is required.");
            return;
        }
        try {
            await updateDressCode(dressCodeInput);
            setDressCode(dressCodeInput);
            setDressCodeError("");
        } catch (error) {
            console.error("Error saving dress code:", error);
            setDressCodeError("Failed to save dress code. Please try again.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.time || !formData.title || !formData.date) {
            setFormError("Date, Time, and Title are required.");
            return;
        }

        try {
            const eventData = { ...formData, timestamp: new Date().toISOString() };
            const newEventId = await addEvent(eventData);

            if (editingId) {
                setEvents((prev) =>
                    prev.map((evt) => (evt.id === editingId ? { ...eventData, id: editingId } : evt))
                );
                setEditingId(null);
            } else {
                setEvents((prev) => [...prev, { ...eventData, id: newEventId }]);
            }

            setFormData({ time: "", title: "", description: "", date: "" });
            setFormError("");
        } catch (error) {
            console.error("Error saving event:", error);
            setFormError("Failed to save event. Please try again.");
        }
    };

    const handleEdit = (event) => {
        setFormData({
            time: event.time,
            title: event.title,
            description: event.description || "",
            date: event.date,
        });
        setEditingId(event.id);
        setFormError("");
        setMode("create"); // Switch to create mode for editing
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this event?")) {
            try {
                await deleteEvent(id);
                setEvents((prev) => prev.filter((evt) => evt.id !== id));
                if (editingId === id) {
                    setEditingId(null);
                    setFormData({ time: "", title: "", description: "", date: "" });
                }
            } catch (error) {
                console.error("Error deleting event:", error);
                alert("Failed to delete event. Please try again.");
            }
        }
    };

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const staggerChildren = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
    };

    return (
        <motion.div className="admin-event" initial="hidden" animate="visible" variants={fadeInUp}>

            {/* Mode Switch Buttons */}
            <div className="mode-switch">
                <button
                    className={`mode-button ${mode === "dressCode" ? "active" : ""}`}
                    onClick={() => setMode("dressCode")}
                >
                    <Shirt size={20} />
                    <span>Dress Code</span>
                </button>
                <button
                    className={`mode-button ${mode === "create" ? "active" : ""}`}
                    onClick={() => setMode("create")}
                >
                    <PlusCircle size={20} />
                    <span>Create Event</span>
                </button>
                <button
                    className={`mode-button ${mode === "manage" ? "active" : ""}`}
                    onClick={() => setMode("manage")}
                >
                    <Calendar size={20} />
                    <span>Manage Events</span>
                </button>
            </div>

            {/* Conditional Rendering Based on Mode */}
            {mode === "dressCode" && (
                <motion.div className="dress-code-section" variants={fadeInUp}>
                    <form onSubmit={handleDressCodeSubmit} className="dress-code-form">
                        <div className="form-group">
                            <label htmlFor="dressCode">Dress Code *</label>
                            <input
                                type="text"
                                id="dressCode"
                                value={dressCodeInput}
                                onChange={handleDressCodeChange}
                                placeholder="Enter dress code"
                                required
                            />
                        </div>
                        {dressCodeError && <p className="form-error">{dressCodeError}</p>}
                        <button type="submit" className="save-button">
                            Save Dress Code
                        </button>
                    </form>
                    {dressCode && (
                        <div className="dress-code-display">
                            <h4>
                                Current Dress Code: <span>{dressCode}</span>
                            </h4>
                        </div>
                    )}
                </motion.div>
            )}

            {mode === "create" && (
                <motion.div variants={fadeInUp}>
                    <form onSubmit={handleSubmit} className="event-form">
                        <div className="form-group">
                            <label htmlFor="date">Date *</label>
                            <input
                                type="date"
                                id="date"
                                name="date"
                                value={formData.date}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="time">Time *</label>
                            <input
                                type="time"
                                id="time"
                                name="time"
                                value={formData.time}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Title *</label>
                            <input
                                type="text"
                                id="title"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                placeholder="Enter event title"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description (Optional)</label>
                            <textarea
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                placeholder="Enter event description"
                            />
                        </div>
                        {formError && <p className="form-error">{formError}</p>}
                        <button type="submit" className="submit-button">
                            {editingId ? "Update Event" : "Add Event"}
                        </button>
                    </form>
                </motion.div>
            )}

            {mode === "manage" && (
                <motion.div className="timeline" initial="hidden" animate="visible" variants={staggerChildren}>
                    {loading ? (
                        <p className="no-data">Loading...</p>
                    ) : events.length > 0 ? (
                        events
                            .sort((a, b) => new Date(`${a.date}T${a.time}`) - new Date(`${b.date}T${b.time}`))
                            .map((event) => (
                                <motion.div key={event.id} className="timeline-item" variants={fadeInUp}>
                                    <div className="timeline-content">
                                        <div className="timeline-time">
                                            {new Date(`${event.date}T${event.time}`).toLocaleString([], {
                                                dateStyle: "short",
                                                timeStyle: "short",
                                            })}
                                        </div>
                                        <div className="timeline-details">
                                            <h4>{event.title}</h4>
                                            {event.description && <p>{event.description}</p>}
                                        </div>
                                        <div className="timeline-actions">
                                            <button onClick={() => handleEdit(event)} className="edit-button" title="Edit">
                                                <Edit size={20} />
                                            </button>
                                            <button onClick={() => handleDelete(event.id)} className="delete-button" title="Delete">
                                                <Trash2 size={20} />
                                            </button>
                                        </div>
                                    </div>
                                </motion.div>
                            ))
                    ) : (
                        <p className="no-data">No events scheduled yet.</p>
                    )}
                </motion.div>
            )}
        </motion.div>
    );
};

export default AdminEvent;