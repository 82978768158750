import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../../contexts/AdminContext";
import { Edit, Trash2, PlusCircle, Settings, Upload } from "lucide-react";
import { toast } from "react-hot-toast";
import { getOurStoryEvents, addOurStoryEvent, updateOurStoryEvent, deleteOurStoryEvent, getOurStoryHeader, setOurStoryHeader } from "../../services/OurStoryService";
import PinataUploader from "../../utils/PinataUploader";
import "../../styles/AdminOurStory.css";

const AdminOurStory = () => {
    const { isAdmin, loading } = useAdmin();
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [headerData, setHeaderData] = useState({ image: "", description: "" });
    const [formData, setFormData] = useState({
        coupleName: "",
        photoDescription: "",
        image: "",
    });
    const [headerFormData, setHeaderFormData] = useState({
        description: "",
        image: "",
    });
    const [editId, setEditId] = useState(null);
    const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
    const [isAdding, setIsAdding] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [headerSelectedFile, setHeaderSelectedFile] = useState(null);
    const uploaderRef = useRef(null);
    const headerUploaderRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch story events
                const eventList = await getOurStoryEvents();
                setEvents(eventList);

                // Fetch header data
                const header = await getOurStoryHeader();
                setHeaderData(header);
                setHeaderFormData({ description: header.description, image: header.image });
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failed to load data.");
            }
        };
        fetchData();
    }, []);

    if (!loading && !isAdmin) {
        navigate("/");
        return null;
    }

    // Handle story event form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle header form input changes
    const handleHeaderInputChange = (e) => {
        const { name, value } = e.target;
        setHeaderFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle story event file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
        }
    };

    // Handle header file change
    const handleHeaderFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setHeaderSelectedFile(file);
        } else {
            setHeaderSelectedFile(null);
        }
    };

    // Handle story event upload completion
    const handleUploadComplete = async (uploadData) => {
        try {
            console.log("Upload complete:", uploadData);
            if (uploadData.name === "Our Story Image") {
                const newEvent = {
                    coupleName: formData.coupleName,
                    photoDescription: formData.photoDescription,
                    image: uploadData.url,
                };
                console.log("Saving event to Firestore:", newEvent);

                if (editId) {
                    await updateOurStoryEvent(editId, newEvent);
                    setEvents(events.map((event) =>
                        event.id === editId ? { ...event, ...newEvent } : event
                    ));
                    toast.success("Story updated successfully!");
                    setEditId(null);
                } else {
                    const newEventId = await addOurStoryEvent(newEvent);
                    console.log("New event ID:", newEventId);
                    const eventList = await getOurStoryEvents();
                    setEvents(eventList);
                    toast.success("Story added successfully!");
                }

                setFormData({ coupleName: "", photoDescription: "", image: "" });
                setSelectedFile(null);
            }
        } catch (error) {
            console.error("Error saving to Firestore:", error);
            toast.error(error.message || "Failed to save story to Firestore.");
        }
    };

    // Handle header upload completion
    const handleHeaderUploadComplete = async (uploadData) => {
        try {
            console.log("Header upload complete:", uploadData);
            if (uploadData.name === "Our Story Header Image") {
                const newHeader = {
                    image: uploadData.url,
                    description: headerFormData.description,
                };
                await setOurStoryHeader(newHeader);
                setHeaderData(newHeader);
                setHeaderFormData({ description: newHeader.description, image: newHeader.url });
                setHeaderSelectedFile(null);
                toast.success("Header updated successfully!");
            }
        } catch (error) {
            console.error("Error saving header to Firestore:", error);
            toast.error(error.message || "Failed to save header to Firestore.");
        }
    };

    // Handle story event form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.coupleName || !formData.photoDescription) {
            toast.error("Please fill in all required fields.");
            return;
        }

        if (!editId && !selectedFile) {
            toast.error("Please select an image to upload.");
            return;
        }

        if (selectedFile) {
            console.log("Starting submission process...");
            console.log("Uploading file to Pinata:", selectedFile.name);
            const files = [selectedFile];
            const titles = { [selectedFile.name]: formData.coupleName || "Untitled" };
            const descriptions = { [selectedFile.name]: formData.photoDescription || "No description" };

            const uploaderElement = document.getElementById("pinata-uploader");
            if (!uploaderElement || !uploaderElement.uploadFiles) {
                toast.error("Uploader element not found.");
                return;
            }

            uploaderElement.uploadFiles(files, titles, descriptions, "Our Story Image");
        } else if (editId) {
            handleUploadComplete({ url: formData.image, name: "Our Story Image" });
        }
    };

    // Handle header form submission
    const handleHeaderSubmit = (e) => {
        e.preventDefault();

        if (!headerFormData.description) {
            toast.error("Please provide a description for the header.");
            return;
        }

        if (!headerFormData.image && !headerSelectedFile) {
            toast.error("Please select a header image to upload.");
            return;
        }

        if (headerSelectedFile) {
            console.log("Starting header submission process...");
            console.log("Uploading header file to Pinata:", headerSelectedFile.name);
            const files = [headerSelectedFile];
            const titles = { [headerSelectedFile.name]: "Our Story Header" };
            const descriptions = { [headerSelectedFile.name]: headerFormData.description || "No description" };

            const uploaderElement = document.getElementById("header-pinata-uploader");
            if (!uploaderElement || !uploaderElement.uploadFiles) {
                toast.error("Header uploader element not found.");
                return;
            }

            uploaderElement.uploadFiles(files, titles, descriptions, "Our Story Header Image");
        } else {
            handleHeaderUploadComplete({ url: headerFormData.image, name: "Our Story Header Image" });
        }
    };

    const handleEdit = (event) => {
        setFormData({
            coupleName: event.coupleName,
            photoDescription: event.photoDescription,
            image: event.image,
        });
        setSelectedFile(null);
        setEditId(event.id);
        setIsAdding(true);
    };

    const handleDelete = async () => {
        try {
            await deleteOurStoryEvent(deleteModal.id);
            setEvents(events.filter((event) => event.id !== deleteModal.id));
            setDeleteModal({ open: false, id: null });
            toast.success("Story deleted successfully!");
        } catch (error) {
            toast.error(error.message);
        }
    };

    const openDeleteModal = (id) => {
        setDeleteModal({ open: true, id });
    };

    const closeDeleteModal = () => {
        setDeleteModal({ open: false, id: null });
    };

    return (
        <div className="admin-our-story">
            <h2>Our Story Management</h2>

            {/* Header Management Section */}
            <div className="header-management">
                <h3>Manage Header Picture and Description</h3>
                <form className="header-form" onSubmit={handleHeaderSubmit}>
                    <textarea
                        name="description"
                        value={headerFormData.description}
                        onChange={handleHeaderInputChange}
                        placeholder="Header Description"
                        rows="3"
                    />
                    <div className="image-upload">
                        <label htmlFor="header-image-upload">
                            <Upload size={20} /> {headerSelectedFile ? headerSelectedFile.name : "Upload Header Image"}
                        </label>
                        <input
                            id="header-image-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleHeaderFileChange}
                            style={{ display: "none" }}
                        />
                        {headerFormData.image && !headerSelectedFile && (
                            <div className="uploaded-image-preview">
                                <p>Current Header Image:</p>
                                <a href={headerFormData.image} target="_blank" rel="noopener noreferrer">
                                    View Image
                                </a>
                            </div>
                        )}
                    </div>
                    <PinataUploader
                        id="header-pinata-uploader"
                        ref={headerUploaderRef}
                        onUploadComplete={handleHeaderUploadComplete}
                    />
                    <button type="submit">Update Header</button>
                </form>
            </div>

            {/* Story Events Management Section */}
            <div className="mode-toggle">
                <button
                    className={`toggle-btn ${isAdding ? "active" : ""}`}
                    onClick={() => setIsAdding(true)}
                >
                    <PlusCircle size={20} /> Add Story
                </button>
                <button
                    className={`toggle-btn ${!isAdding ? "active" : ""}`}
                    onClick={() => setIsAdding(false)}
                >
                    <Settings size={20} /> Manage Story
                </button>
            </div>

            {isAdding && (
                <form className="event-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="coupleName"
                        value={formData.coupleName}
                        onChange={handleInputChange}
                        placeholder="Couple Name (e.g., John & Jane)"
                        required
                    />
                    <textarea
                        name="photoDescription"
                        value={formData.photoDescription}
                        onChange={handleInputChange}
                        placeholder="Photo Description"
                        required
                        rows="4"
                    />
                    <div className="image-upload">
                        <label htmlFor="image-upload">
                            <Upload size={20} /> {selectedFile ? selectedFile.name : "Upload Image"}
                        </label>
                        <input
                            id="image-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                        />
                        {formData.image && !selectedFile && editId && (
                            <div className="uploaded-image-preview">
                                <p>Current Image:</p>
                                <a href={formData.image} target="_blank" rel="noopener noreferrer">
                                    View Image
                                </a>
                            </div>
                        )}
                    </div>
                    <PinataUploader
                        id="pinata-uploader"
                        ref={uploaderRef}
                        onUploadComplete={handleUploadComplete}
                    />
                    <button type="submit">{editId ? "Update" : "Add"} Story</button>
                </form>
            )}

            {!isAdding && (
                <div className="event-list">
                    {events.length === 0 ? (
                        <p>No stories added yet.</p>
                    ) : (
                        events.map((event) => (
                            <div key={event.id} className="event-item">
                                <div className="event-info">
                                    <p><strong>Couple Name:</strong> {event.coupleName}</p>
                                    <p><strong>Photo Description:</strong> {event.photoDescription}</p>
                                    <p><strong>Image URL:</strong> <a href={event.image} target="_blank" rel="noopener noreferrer">View Image</a></p>
                                </div>
                                <div className="event-actions">
                                    <button onClick={() => handleEdit(event)}>
                                        <Edit size={20} />
                                    </button>
                                    <button onClick={() => openDeleteModal(event.id)}>
                                        <Trash2 size={20} />
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}

            {deleteModal.open && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Confirm Deletion</h3>
                        <p>Are you sure you want to delete this story?</p>
                        <div className="modal-buttons">
                            <button className="modal-cancel" onClick={closeDeleteModal}>
                                Cancel
                            </button>
                            <button className="modal-confirm" onClick={handleDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminOurStory;