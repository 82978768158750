import { useState } from "react";
import { Upload } from "lucide-react";
import { toast } from "react-hot-toast";
import { addPhoto } from "../services/AdminPhotosService";
import PinataUploader from "../utils/PinataUploader";
import "../styles/AdminPhotosUpload.css";

const AdminPhotosUpload = () => {
    const [photoFiles, setPhotoFiles] = useState([]);
    const [videoFiles, setVideoFiles] = useState([]);
    const [photoTitles, setPhotoTitles] = useState({});
    const [videoTitles, setVideoTitles] = useState({});
    const [photoDescriptions, setPhotoDescriptions] = useState({});
    const [videoDescriptions, setVideoDescriptions] = useState({});

    const handleFileChange = (e, type) => {
        const selectedFiles = Array.from(e.target.files);
        const titles = type === "photo" ? photoTitles : videoTitles;
        const descriptions = type === "photo" ? photoDescriptions : videoDescriptions;
        const setTitles = type === "photo" ? setPhotoTitles : setVideoTitles;
        const setDescriptions = type === "photo" ? setPhotoDescriptions : setVideoDescriptions;
        const setFiles = type === "photo" ? setPhotoFiles : setVideoFiles;

        setFiles(selectedFiles);
        const newTitles = { ...titles };
        const newDescriptions = { ...descriptions };
        selectedFiles.forEach((file) => {
            newTitles[file.name] = newTitles[file.name] || "";
            newDescriptions[file.name] = newDescriptions[file.name] || "";
        });
        setTitles(newTitles);
        setDescriptions(newDescriptions);
    };

    const handleTitleChange = (fileName, value, type) => {
        const setTitles = type === "photo" ? setPhotoTitles : setVideoTitles;
        setTitles((prev) => ({ ...prev, [fileName]: value }));
    };

    const handleDescriptionChange = (fileName, value, type) => {
        const setDescriptions = type === "photo" ? setPhotoDescriptions : setVideoDescriptions;
        setDescriptions((prev) => ({ ...prev, [fileName]: value }));
    };

    const handleUploadComplete = async (mediaData, mediaType) => {
        try {
            const newMedia = {
                url: mediaData.url,
                title: mediaData.title || "", // Changed from "Untitled" to empty string
                description: mediaData.description || "", // Already correct, just confirming
                type: mediaType, // "image" or "video" from form
                timestamp: new Date().toISOString(),
            };
            await addPhoto(newMedia);
            toast.success(`${newMedia.title || "Media"} uploaded successfully!`); // Adjusted toast message
        } catch (err) {
            toast.error(`Failed to save ${mediaType} to Firestore.`);
            console.error("Firestore save error:", err);
        }
    };

    const handleSubmit = (e, type) => {
        e.preventDefault();
        const files = type === "photo" ? photoFiles : videoFiles;
        const titles = type === "photo" ? photoTitles : videoTitles;
        const descriptions = type === "photo" ? photoDescriptions : videoDescriptions;
        const uploaderId = type === "photo" ? "photo-uploader" : "video-uploader";
        const mediaType = type === "photo" ? "image" : "video";

        if (files.length === 0) {
            toast.error(`Please select at least one ${type}.`);
            return;
        }

        const uploader = document.getElementById(uploaderId);
        if (uploader && uploader.uploadFiles) {
            uploader.uploadFiles(files, titles, descriptions, `Admin ${type} Upload`);
        } else {
            toast.error(`Uploader for ${type} not ready.`);
            return;
        }

        if (type === "photo") {
            setPhotoFiles([]);
            setPhotoTitles({});
            setPhotoDescriptions({});
        } else {
            setVideoFiles([]);
            setVideoTitles({});
            setVideoDescriptions({});
        }
        e.target.reset();
    };

    return (
        <div className="admin-photos-upload">
            <h3>Upload Media</h3>
            <div className="upload-grid">
                <form onSubmit={(e) => handleSubmit(e, "photo")} className="upload-form">
                    <div className="form-group">
                        <label htmlFor="photo-upload">
                            <Upload size={20} /> Upload Photos
                        </label>
                        <input
                            type="file"
                            id="photo-upload"
                            accept="image/*"
                            multiple
                            onChange={(e) => handleFileChange(e, "photo")}
                            style={{ display: "none" }}
                        />
                    </div>
                    <div className="file-details">
                        {photoFiles.length > 0 ? (
                            photoFiles.map((file) => (
                                <div key={file.name} className="file-input-group">
                                    <p>{file.name}</p>
                                    <div className="form-group">
                                        <label>Title:</label>
                                        <input
                                            type="text"
                                            value={photoTitles[file.name] || ""}
                                            onChange={(e) => handleTitleChange(file.name, e.target.value, "photo")}
                                            placeholder="Enter title (optional)"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Description:</label>
                                        <textarea
                                            value={photoDescriptions[file.name] || ""}
                                            onChange={(e) =>
                                                handleDescriptionChange(file.name, e.target.value, "photo")
                                            }
                                            placeholder="Enter description (optional)"
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No photos selected yet.</p>
                        )}
                        <button type="submit">Upload Photos</button>
                    </div>
                </form>

                <form onSubmit={(e) => handleSubmit(e, "video")} className="upload-form">
                    <div className="form-group">
                        <label htmlFor="video-upload">
                            <Upload size={20} /> Upload Videos
                        </label>
                        <input
                            type="file"
                            id="video-upload"
                            accept="video/*"
                            multiple
                            onChange={(e) => handleFileChange(e, "video")}
                            style={{ display: "none" }}
                        />
                    </div>
                    <div className="file-details">
                        {videoFiles.length > 0 ? (
                            videoFiles.map((file) => (
                                <div key={file.name} className="file-input-group">
                                    <p>{file.name}</p>
                                    <div className="form-group">
                                        <label>Title:</label>
                                        <input
                                            type="text"
                                            value={videoTitles[file.name] || ""}
                                            onChange={(e) => handleTitleChange(file.name, e.target.value, "video")}
                                            placeholder="Enter title (optional)"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Description:</label>
                                        <textarea
                                            value={videoDescriptions[file.name] || ""}
                                            onChange={(e) =>
                                                handleDescriptionChange(file.name, e.target.value, "video")
                                            }
                                            placeholder="Enter description (optional)"
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No videos selected yet.</p>
                        )}
                        <button type="submit">Upload Videos</button>
                    </div>
                </form>
            </div>

            <PinataUploader
                id="photo-uploader"
                onUploadComplete={(mediaData) => handleUploadComplete(mediaData, "image")}
            />
            <PinataUploader
                id="video-uploader"
                onUploadComplete={(mediaData) => handleUploadComplete(mediaData, "video")}
            />
        </div>
    );
};

export default AdminPhotosUpload;