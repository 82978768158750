import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { getHomeImages, startImagesListener, getImagesFromIndexedDB } from "../services/HomeService";
import HomeButtonIcon from "../components/HomeButtonIcon"; // Renamed import
import CountDown from "../components/CountDown";
import DeveloperCreditWidget from "../components/DeveloperCreditWidget";
import "../styles/Home.css";

const buttonData = [
    { id: "1", title: "Our Story", icon: "story", screen: "OurStory" },
    { id: "2", title: "Our Day", icon: "day", screen: "OurDay" },
    { id: "3", title: "Photos", icon: "photos", screen: "Photos" },
    { id: "4", title: "FAQ", icon: "faq", screen: "Faq" },
    { id: "5", title: "Wishes", icon: "wishes", screen: "Wishes" },
    { id: "6", title: "Gifts", icon: "gifts", screen: "Gifts" },
];

const Home = () => {
    const [images, setImages] = useState([]);
    const [index, setIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const fallbackImage = {
        id: "fallback",
        url: "/images/logo.png",
    };

    useEffect(() => {
        const loadImages = async () => {
            let storedImages = await getImagesFromIndexedDB();
            if (storedImages.length > 0) {
                setImages(storedImages);
            } else {
                const freshImages = await getHomeImages();
                setImages(freshImages.length > 0 ? freshImages : [fallbackImage]);
            }
            setIsLoading(false);
        };

        loadImages();
        const unsubscribe = startImagesListener((updatedImages) => {
            setImages(updatedImages.length > 0 ? updatedImages : [fallbackImage]);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const slideshowInterval = setInterval(() => {
            setIndex((prev) => (prev + 1) % (images.length || 1));
        }, 5000);

        return () => clearInterval(slideshowInterval);
    }, [images]);

    const currentImage = images[index] || fallbackImage;

    useEffect(() => {
        images.forEach((image) => {
            const img = new Image();
            img.src = image.url;
        });
    }, [images]);

    return (
        <div className="home-container">
            <main className="home-content">
                <section className="image-container">
                    {isLoading ? (
                        <div className="image-placeholder">Loading...</div>
                    ) : (
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentImage.id}
                                className="image-wrapper"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.6, ease: "easeInOut" }}
                            >
                                <img
                                    src={currentImage.url}
                                    className="full-image"
                                    alt="Slideshow"
                                />
                            </motion.div>
                        </AnimatePresence>
                    )}
                </section>
                <CountDown />
            </main>
            <footer className="button-footer">
                <div className="button-grid">
                    {buttonData.map((item) => (
                        <motion.div
                            key={item.id}
                            className="button-wrapper"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <HomeButtonIcon {...item} />
                        </motion.div>
                    ))}
                </div>
            </footer>
            <DeveloperCreditWidget />
        </div>
    );
};

export default Home;