import { useState, useEffect } from "react";
import { doc, deleteDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";
import { Loader2 } from "lucide-react";
import { getHomeImages, startImagesListener } from "../services/HomeService";
import toast from "react-hot-toast";
import "../styles/ManageImages.css";

const ManageImages = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteModal, setDeleteModal] = useState({ open: false, imageId: null });

    useEffect(() => {
        const loadInitialImages = async () => {
            try {
                const cachedImages = await getHomeImages();
                setImages(cachedImages);
                setLoading(false);
            } catch (err) {
                setError("Failed to load images from cache.");
                setLoading(false);
            }
        };

        loadInitialImages();

        const unsubscribe = startImagesListener((updatedImages) => {
            setImages(updatedImages);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const handleDelete = async () => {
        const id = deleteModal.imageId;
        const toastId = toast.loading("Deleting image...");
        try {
            setLoading(true);
            await deleteDoc(doc(firestore, "homeImages", id));
            setImages((prevImages) => prevImages.filter((image) => image.id !== id));
            setDeleteModal({ open: false, imageId: null });
            setLoading(false);
            toast.success("Image deleted successfully!", { id: toastId });
        } catch (err) {
            console.error("Delete error:", err);
            setError("Failed to delete image. Please try again.");
            setLoading(false);
            toast.error("Failed to delete image.", { id: toastId });
        }
    };

    const openDeleteModal = (id) => {
        setDeleteModal({ open: true, imageId: id });
    };

    const closeDeleteModal = () => {
        setDeleteModal({ open: false, imageId: null });
    };

    if (loading) {
        return (
            <div className="loader-container">
                <Loader2 className="loader" size={40} />
                <p>{error ? "Error occurred, retrying..." : "Loading images..."}</p>
            </div>
        );
    }

    if (error && images.length === 0) {
        return <div>{error}</div>;
    }

    return (
        <div className="manage-images">
            <h2>Manage Images</h2>
            {images.length === 0 ? (
                <p>No images available.</p>
            ) : (
                <div className="images-grid">
                    {images.map((image) => (
                        <div key={image.id} className="image-card">
                            {image.url && image.url.startsWith("https://") ? (
                                <img src={image.url} alt={image.title} className="image" />
                            ) : (
                                <p>Invalid image URL</p>
                            )}
                            <h3 className="image-title">{image.title}</h3>
                            <p className="image-description">{image.description}</p>
                            <button
                                className="delete-button"
                                onClick={() => openDeleteModal(image.id)}
                                disabled={loading}
                            >
                                Delete
                            </button>
                        </div>
                    ))}
                </div>
            )}
            {deleteModal.open && (
                <div className="modal-overlay">
                    <div className="modal"> {/* Fixed 'class' to 'className' */}
                        <h3>Confirm Deletion</h3>
                        <p>Are you sure you want to delete this image?</p>
                        <div className="modal-buttons">
                            <button className="modal-cancel" onClick={closeDeleteModal}>
                                Cancel
                            </button>
                            <button
                                className="modal-confirm"
                                onClick={handleDelete}
                                disabled={loading}
                            >
                                {loading ? "Deleting..." : "Delete"}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default ManageImages;