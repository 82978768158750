import { Heart } from "lucide-react";
import { motion } from "framer-motion";
import "../styles/DeveloperCreditWidget.css";

const DeveloperCreditWidget = () => {
    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0, 
            transition: { 
                duration: 0.6, 
                ease: "easeOut",
                staggerChildren: 0.2 
            } 
        },
    };

    const childFade = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { 
            opacity: 1, 
            scale: 1, 
            transition: { 
                duration: 0.4, 
                ease: "easeOut" 
            } 
        },
    };

    return (
        <motion.div
            className="developer-credit-widget"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
        >
            <motion.span className="credit-text" variants={childFade}>
                Developed with <Heart size={20} className="heart-icon" /> by Ayeye Studios
            </motion.span>
            <motion.div className="social-links" variants={childFade}>
                <a
                    href="https://x.com/webdev_v2"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                >
                    <svg
                        className="social-icon x-icon"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>X</title>
                        <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"/>
                    </svg>
                </a>
                <a
                    href="https://www.instagram.com/ayeyestudio?igsh=MW8ydWRzNzg2cmEzZQ=="
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                >
                    <svg className="social-icon instagram-icon" width="24" height="24" viewBox="0 0 24 24">
                        <defs>
                            <linearGradient id="instagram-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                <stop offset="0%" style={{ stopColor: "#E1306C", stopOpacity: 1 }} />
                                <stop offset="50%" style={{ stopColor: "#F77737", stopOpacity: 1 }} />
                                <stop offset="100%" style={{ stopColor: "#FCAF45", stopOpacity: 1 }} />
                            </linearGradient>
                        </defs>
                        <path
                            d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.326 3.608 1.301.975.975 1.24 2.242 1.301 3.608.058 1.266.07 1.646.07 4.85s-.012 3.584-.07 4.85c-.062 1.366-.326 2.633-1.301 3.608-.975.975-2.242 1.24-3.608 1.301-1.266.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.366-.062-2.633-.326-3.608-1.301-.975-.975-1.24-2.242-1.301-3.608-.058-1.266-.07-1.646-.07-4.85s.012-3.584.07-4.85c.062-1.366.326-2.633 1.301-3.608.975-.975 2.242-1.24 3.608-1.301 1.266-.058 1.646-.07 4.85-.07zm0-2.163c-3.259 0-3.667.014-4.947.072-1.405.064-2.813.364-3.986 1.537S1.594 4.405 1.53 5.81c-.058 1.28-.072 1.688-.072 4.947s.014 3.667.072 4.947c.064 1.405.364 2.813 1.537 3.986s2.581 1.473 3.986 1.537c1.28.058 1.688.072 4.947.072s3.667-.014 4.947-.072c1.405-.064 2.813-.364 3.986-1.537s1.473-2.581 1.537-3.986c.058-1.28.072-1.688.072-4.947s-.014-3.667-.072-4.947c-.064-1.405-.364-2.813-1.537-3.986S19.595 1.594 18.19 1.53c-1.28-.058-1.688-.072-4.947-.072zM12 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zm0 10.162a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.406-11.845a1.44 1.44 0 1 0 0 2.88 1.44 1.44 0 0 0 0-2.88z"
                            stroke="url(#instagram-gradient)"
                            fill="none"
                            strokeWidth="2"
                        />
                    </svg>
                </a>
            </motion.div>
        </motion.div>
    );
};

export default DeveloperCreditWidget;