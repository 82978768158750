import { useState, useEffect, useCallback } from "react";
import { toast } from "react-hot-toast";
import { openDB } from "idb";
import axios from "axios";
import "../styles/PinataUploader.css";

// IndexedDB setup inside the component
const DB_NAME = "PinataUploaderDB";
const STORE_NAME = "uploadQueue";

const PinataUploader = ({ id, onUploadComplete }) => {
    const [queue, setQueue] = useState([]);
    const [uploading, setUploading] = useState(false);

    // Pinata API configuration
    const PINATA_API_KEY = process.env.REACT_APP_PINATA_API_KEY;
    const PINATA_SECRET_API_KEY = process.env.REACT_APP_PINATA_SECRET_API_KEY;
    const PINATA_UPLOAD_URL = "https://api.pinata.cloud/pinning/pinFileToIPFS";

    // Load queue from IndexedDB on mount
    useEffect(() => {
        let isMounted = true;

        const loadQueue = async () => {
            try {
                // Open a fresh database connection
                const db = await openDB(DB_NAME, 1, {
                    upgrade(db) {
                        if (!db.objectStoreNames.contains(STORE_NAME)) {
                            db.createObjectStore(STORE_NAME, { keyPath: "id" });
                        }
                    },
                });
                const storedQueue = await db.getAll(STORE_NAME);
                if (isMounted && storedQueue.length > 0) {
                    setQueue(storedQueue);
                    const pending = storedQueue.filter((item) => !item.completed);
                    if (pending.length > 0) {
                        startUploadQueue();
                    }
                }
                // No need to close the db manually; let it close naturally
            } catch (err) {
                console.error("Failed to load queue from IndexedDB:", err);
                toast.error("Error loading upload queue.");
            }
        };

        loadQueue();

        return () => {
            isMounted = false; // Prevent state updates after unmount
        };
    }, []); // Empty deps: runs once on mount

    // Save queue to IndexedDB whenever it changes
    useEffect(() => {
        const saveQueue = async () => {
            try {
                const db = await openDB(DB_NAME, 1);
                const tx = db.transaction(STORE_NAME, "readwrite");
                const store = tx.objectStore(STORE_NAME);
                await store.clear();
                for (const item of queue) {
                    await store.put(item);
                }
                await tx.done;
            } catch (err) {
                console.error("Failed to save queue to IndexedDB:", err);
                toast.error("Error saving upload queue.");
            }
        };
        if (queue.length > 0) {
            saveQueue();
        }
    }, [queue]);

    // Upload a single file to Pinata
    const uploadFileToPinata = useCallback(async (queueItem) => {
        const formData = new FormData();
        formData.append("file", queueItem.file);

        try {
            const response = await axios.post(PINATA_UPLOAD_URL, formData, {
                headers: {
                    pinata_api_key: PINATA_API_KEY,
                    pinata_secret_api_key: PINATA_SECRET_API_KEY,
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setQueue((prev) =>
                        prev.map((item) =>
                            item.id === queueItem.id
                                ? { ...item, progress: percentCompleted }
                                : item
                        )
                    );
                    toast(`${queueItem.name}: ${percentCompleted}%`, {
                        id: queueItem.id,
                        duration: Infinity,
                    });
                },
            });

            const ipfsHash = response.data.IpfsHash;
            const directUrl = `https://gateway.pinata.cloud/ipfs/${ipfsHash}`;
            return directUrl;
        } catch (err) {
            console.error("Upload error:", err);
            throw new Error("Failed to upload to Pinata.");
        }
    }, []);

    // Process the upload queue
    const startUploadQueue = useCallback(async () => {
        if (uploading) return;
        setUploading(true);

        const pendingItems = queue.filter((item) => !item.completed);
        for (const item of pendingItems) {
            toast(`${item.name}: 0%`, {
                id: item.id,
                duration: Infinity,
            });

            try {
                const url = await uploadFileToPinata(item);
                setQueue((prev) =>
                    prev.map((q) =>
                        q.id === item.id
                            ? { ...q, completed: true, progress: 100, url }
                            : q
                    )
                );
                toast.success(`${item.name}: Upload Complete!`, {
                    id: item.id,
                    duration: 3000,
                });
                onUploadComplete({
                    url,
                    title: item.title,
                    description: item.description,
                    name: item.name,
                });
            } catch (err) {
                setQueue((prev) =>
                    prev.map((q) =>
                        q.id === item.id
                            ? { ...q, completed: true, progress: 0 }
                            : q
                    )
                );
                toast.error(`${item.name}: Upload Failed`, {
                    id: item.id,
                    duration: 3000,
                });
            }
        }

        setUploading(false);
        setTimeout(() => {
            setQueue((prev) => prev.filter((item) => !item.completed));
        }, 5000);
    }, [queue, uploading, onUploadComplete, uploadFileToPinata]);

    // Resume uploads when queue changes
    useEffect(() => {
        if (queue.length > 0 && !uploading) {
            startUploadQueue();
        }
    }, [queue, startUploadQueue, uploading]);

    // Expose the uploadFiles method to the parent component
    useEffect(() => {
        if (id) {
            document.getElementById(id).uploadFiles = (files, titles, descriptions, name) => {
                if (files.length === 0) {
                    toast.error("Please select at least one image.");
                    return;
                }

                const newQueueItems = files.map((file) => ({
                    id: `${file.name}-${Date.now()}`,
                    file,
                    name: file.name,
                    completed: false,
                    progress: 0,
                    url: null,
                    title: titles[file.name] || "",
                    description: descriptions[file.name] || "",
                    name,
                }));

                setQueue((prev) => [...prev, ...newQueueItems]);
            };
        }
    }, [id]);

    return (
        <div id={id} className="pinata-uploader-widget">
            {queue.length > 0 && (
                <div className="queue-status">
                    <h3>Upload Queue</h3>
                    {queue.map((item) => (
                        <div key={item.id} className="queue-item">
                            <p>{item.name}</p>
                            <div className="progress-bar">
                                <div
                                    className="progress"
                                    style={{ width: `${item.progress}%` }}
                                ></div>
                            </div>
                            {item.url && (
                                <a href={item.url} target="_blank" rel="noopener noreferrer">
                                    View Image
                                </a>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PinataUploader;