import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Edit, Trash2, PlusCircle, Map } from "lucide-react";
import { getLocation, addLocation, deleteLocation } from "../services/AdminOurDayService";
import "../styles/AdminLocation.css";

const AdminLocation = () => {
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        address: "",
        venue: "",
        mapUrl: "",
    });
    const [editingId, setEditingId] = useState(null);
    const [formError, setFormError] = useState("");
    const [mode, setMode] = useState("create"); // "create" or "manage"

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const locationData = await getLocation();
                setLocations(locationData || []);
                setLoading(false);
            } catch (error) {
                console.error("Failed to load location data:", error);
                setLoading(false);
            }
        };
        fetchLocations();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.address || !formData.venue) {
            setFormError("Address and Venue are required.");
            return;
        }

        try {
            const locationData = {
                ...formData,
                timestamp: new Date().toISOString(),
            };

            const newLocationId = await addLocation(locationData);

            if (editingId) {
                setLocations((prev) =>
                    prev.map((loc) => (loc.id === editingId ? { ...locationData, id: editingId } : loc))
                );
                setEditingId(null);
            } else {
                setLocations((prev) => [...prev, { ...locationData, id: newLocationId }]);
            }

            setFormData({ address: "", venue: "", mapUrl: "" });
            setFormError("");
        } catch (error) {
            console.error("Error saving location:", error);
            setFormError("Failed to save location. Please try again.");
        }
    };

    const handleEdit = (location) => {
        setFormData({
            address: location.address,
            venue: location.venue,
            mapUrl: location.mapUrl || "",
        });
        setEditingId(location.id);
        setFormError("");
        setMode("create"); // Switch to create mode for editing
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this location?")) {
            try {
                await deleteLocation(id);
                setLocations((prev) => prev.filter((loc) => loc.id !== id));
                if (editingId === id) {
                    setEditingId(null);
                    setFormData({ address: "", venue: "", mapUrl: "" });
                }
            } catch (error) {
                console.error("Error deleting location:", error);
                alert("Failed to delete location. Please try again.");
            }
        }
    };

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const staggerChildren = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
    };

    return (
        <motion.div className="admin-location" initial="hidden" animate="visible" variants={fadeInUp}>
            <h3>Location Management</h3>

            {/* Mode Switch Buttons */}
            <div className="mode-switch">
                <button
                    className={`mode-button ${mode === "create" ? "active" : ""}`}
                    onClick={() => setMode("create")}
                >
                    <PlusCircle size={20} />
                    <span>Create Location</span>
                </button>
                <button
                    className={`mode-button ${mode === "manage" ? "active" : ""}`}
                    onClick={() => setMode("manage")}
                >
                    <Map size={20} />
                    <span>Manage Locations</span>
                </button>
            </div>

            {/* Conditional Rendering Based on Mode */}
            {mode === "create" && (
                <motion.div variants={fadeInUp}>
                    <form onSubmit={handleSubmit} className="location-form">
                        <div className="form-group">
                            <label htmlFor="address">Address *</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                placeholder="Enter the address"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="venue">Venue *</label>
                            <input
                                type="text"
                                id="venue"
                                name="venue"
                                value={formData.venue}
                                onChange={handleInputChange}
                                placeholder="Enter the venue name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mapUrl">Map URL (Optional)</label>
                            <input
                                type="url"
                                id="mapUrl"
                                name="mapUrl"
                                value={formData.mapUrl}
                                onChange={handleInputChange}
                                placeholder="Enter a map URL (e.g., Google Maps link)"
                            />
                        </div>
                        {formError && <p className="form-error">{formError}</p>}
                        <button type="submit" className="submit-button">
                            {editingId ? "Update Location" : "Add Location"}
                        </button>
                    </form>
                </motion.div>
            )}

            {mode === "manage" && (
                <motion.div className="location-list" initial="hidden" animate="visible" variants={staggerChildren}>
                    {loading ? (
                        <p className="no-data">Loading...</p>
                    ) : locations.length > 0 ? (
                        locations.map((location) => (
                            <motion.div key={location.id} className="location-item" variants={fadeInUp}>
                                <div className="location-info">
                                    <h4>{location.venue}</h4>
                                    <p><strong>Address:</strong> {location.address}</p>
                                    {location.mapUrl && (
                                        <p>
                                            <strong>Map:</strong>{" "}
                                            <a href={location.mapUrl} target="_blank" rel="noopener noreferrer">
                                                View on Map
                                            </a>
                                        </p>
                                    )}
                                </div>
                                <div className="location-actions">
                                    <button onClick={() => handleEdit(location)} className="edit-button" title="Edit">
                                        <Edit size={20} />
                                    </button>
                                    <button onClick={() => handleDelete(location.id)} className="delete-button" title="Delete">
                                        <Trash2 size={20} />
                                    </button>
                                </div>
                            </motion.div>
                        ))
                    ) : (
                        <p className="no-data">No location details available yet.</p>
                    )}
                </motion.div>
            )}
        </motion.div>
    );
};

export default AdminLocation;