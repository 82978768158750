import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { MapPin, Map, Home, Building } from "lucide-react";
import { getLocation } from "../services/AdminOurDayService";
import "../styles/Location.css";

const Location = () => {
    const [location, setLocation] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const locationData = await getLocation();
                const sortedLocation = locationData?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0];
                setLocation(sortedLocation);
                setLoading(false);
            } catch (error) {
                console.error("Failed to load location data:", error);
                setLoading(false);
            }
        };
        fetchLocation();
    }, []);

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    return (
        <motion.div className="location-details" initial="hidden" animate="visible" variants={fadeInUp}>
            {loading ? (
                <p className="no-data">Loading...</p>
            ) : location ? (
                <>
                    <h3>
                        <MapPin size={28} /> Location
                    </h3>
                    <div className="location-info">
                        <div className="info-container address-container">
                            <p className="location-address">
                                <Home size={20} /> {location.address || "No address available."}
                            </p>
                        </div>
                        <div className="info-container venue-container">
                            <p className="location-venue">
                                <Building size={20} /> <strong>Venue:</strong> {location.venue || "Venue not specified."}
                            </p>
                        </div>
                    </div>
                    {location.mapUrl && (
                        <div className="map-container">
                            <iframe
                                src={convertToOSMEmbedUrl(location.mapUrl)}
                                width="100%"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                title="Location Map (OpenStreetMap)"
                            ></iframe>
                            <a
                                href={location.mapUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="map-link"
                            >
                                <Map size={20} /> View on Google Maps
                            </a>
                        </div>
                    )}
                </>
            ) : (
                <p className="no-data">No location details available yet.</p>
            )}
        </motion.div>
    );
};

// Helper function to convert Google Maps URL to OpenStreetMap embed URL
const convertToOSMEmbedUrl = (url) => {
    try {
        const coordMatch = url.match(/@([-.\d]+),([-.\d]+)/);
        const lat = coordMatch ? coordMatch[1] : "7.6426726";
        const lng = coordMatch ? coordMatch[2] : "5.2335957";

        return `https://www.openstreetmap.org/export/embed.html?bbox=${lng - 0.01},${lat - 0.01},${lng - -0.01},${lat - -0.01}&layer=mapnik&marker=${lat},${lng}`;
    } catch (error) {
        console.error("Error converting to OpenStreetMap embed URL:", error);
        return "https://www.openstreetmap.org/export/embed.html?bbox=5.2235957,7.6326726,5.2435957,7.6526726&layer=mapnik&marker=7.6426726,5.2335957";
    }
};

export default Location;