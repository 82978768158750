import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useAdmin } from "../contexts/AdminContext";
import Loading from "../components/Loading";

const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();
    const { isAdmin, loading } = useAdmin();

    // 🔥 Fix: Show loading if authentication is still in progress
    if (loading) return <Loading />;
    if (!user) return <Navigate to="/admin/login" replace />;
    if (!isAdmin) return <Navigate to="/" replace />; // Redirect non-admins to main page

    return children;
};

export default ProtectedRoute;
