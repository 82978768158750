import { firestore } from "../config/firebase";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, setDoc, getDoc } from "firebase/firestore";
import { openDB } from "idb";

const ourStoryCollection = collection(firestore, "ourStoryEvents");
const ourStoryHeaderDoc = doc(firestore, "ourStoryHeader", "header");

// Initialize IndexedDB
const DB_NAME = "OurStoryDB";
const DB_VERSION = 1;
const STORY_EVENTS_STORE = "ourStoryEvents";
const HEADER_STORE = "ourStoryHeader";

const initDB = async () => {
    return openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
            // Create object store for story events
            if (!db.objectStoreNames.contains(STORY_EVENTS_STORE)) {
                db.createObjectStore(STORY_EVENTS_STORE, { keyPath: "id" });
            }
            // Create object store for header (single entry with key "header")
            if (!db.objectStoreNames.contains(HEADER_STORE)) {
                db.createObjectStore(HEADER_STORE);
            }
        },
    });
};

// Fetch all story events with caching
export const getOurStoryEvents = async () => {
    try {
        const db = await initDB();

        // Try to get cached data
        const cachedEvents = await db.getAll(STORY_EVENTS_STORE);
        if (cachedEvents && cachedEvents.length > 0) {
            console.log("Returning cached story events from IndexedDB");
            // Return cached data immediately
            setTimeout(async () => {
                // Fetch fresh data in the background and update cache
                try {
                    const snapshot = await getDocs(ourStoryCollection);
                    const freshEvents = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        coupleName: doc.data().coupleName,
                        photoDescription: doc.data().photoDescription,
                        image: doc.data().image,
                    }));
                    // Update cache
                    const tx = db.transaction(STORY_EVENTS_STORE, "readwrite");
                    const store = tx.objectStore(STORY_EVENTS_STORE);
                    await store.clear();
                    freshEvents.forEach((event) => store.put(event));
                    await tx.done;
                    console.log("Updated story events cache in IndexedDB");
                } catch (error) {
                    console.error("Error updating story events cache:", error);
                }
            }, 0);
            return cachedEvents;
        }

        // If no cache, fetch from Firestore
        const snapshot = await getDocs(ourStoryCollection);
        const events = snapshot.docs.map((doc) => ({
            id: doc.id,
            coupleName: doc.data().coupleName,
            photoDescription: doc.data().photoDescription,
            image: doc.data().image,
        }));

        // Cache the fetched data
        const tx = db.transaction(STORY_EVENTS_STORE, "readwrite");
        const store = tx.objectStore(STORY_EVENTS_STORE);
        await store.clear();
        events.forEach((event) => store.put(event));
        await tx.done;
        console.log("Cached story events in IndexedDB");

        return events;
    } catch (error) {
        console.error("Error fetching Our Story events:", error);
        throw new Error("Failed to fetch events: " + error.message);
    }
};

// Add a new story event
export const addOurStoryEvent = async (event) => {
    try {
        const docRef = await addDoc(ourStoryCollection, event);
        console.log("Event added with ID:", docRef.id);

        // Update cache
        const db = await initDB();
        const newEvent = { ...event, id: docRef.id };
        const tx = db.transaction(STORY_EVENTS_STORE, "readwrite");
        const store = tx.objectStore(STORY_EVENTS_STORE);
        await store.put(newEvent);
        await tx.done;
        console.log("Added new event to IndexedDB cache");

        return docRef.id;
    } catch (error) {
        console.error("Error adding event:", error);
        throw new Error("Failed to add event: " + error.message);
    }
};

// Update an existing story event
export const updateOurStoryEvent = async (id, event) => {
    try {
        const eventDoc = doc(firestore, "ourStoryEvents", id);
        await updateDoc(eventDoc, event);
        console.log("Event updated with ID:", id);

        // Update cache
        const db = await initDB();
        const updatedEvent = { ...event, id };
        const tx = db.transaction(STORY_EVENTS_STORE, "readwrite");
        const store = tx.objectStore(STORY_EVENTS_STORE);
        await store.put(updatedEvent);
        await tx.done;
        console.log("Updated event in IndexedDB cache");
    } catch (error) {
        console.error("Error updating event:", error);
        throw new Error("Failed to update event: " + error.message);
    }
};

// Delete a story event
export const deleteOurStoryEvent = async (id) => {
    try {
        const eventDoc = doc(firestore, "ourStoryEvents", id);
        await deleteDoc(eventDoc);
        console.log("Event deleted with ID:", id);

        // Update cache
        const db = await initDB();
        const tx = db.transaction(STORY_EVENTS_STORE, "readwrite");
        const store = tx.objectStore(STORY_EVENTS_STORE);
        await store.delete(id);
        await tx.done;
        console.log("Deleted event from IndexedDB cache");
    } catch (error) {
        console.error("Error deleting event:", error);
        throw new Error("Failed to delete event: " + error.message);
    }
};

// Fetch the header picture and description with caching
export const getOurStoryHeader = async () => {
    try {
        const db = await initDB();

        // Try to get cached header
        const cachedHeader = await db.get(HEADER_STORE, "header");
        if (cachedHeader) {
            console.log("Returning cached header from IndexedDB");
            // Return cached data immediately
            setTimeout(async () => {
                // Fetch fresh data in the background and update cache
                try {
                    const docSnap = await getDoc(ourStoryHeaderDoc);
                    let freshHeader;
                    if (docSnap.exists()) {
                        freshHeader = {
                            image: docSnap.data().image || "",
                            description: docSnap.data().description || "",
                        };
                    } else {
                        freshHeader = { image: "", description: "" };
                    }
                    // Update cache
                    const tx = db.transaction(HEADER_STORE, "readwrite");
                    const store = tx.objectStore(HEADER_STORE);
                    await store.put(freshHeader, "header");
                    await tx.done;
                    console.log("Updated header cache in IndexedDB");
                } catch (error) {
                    console.error("Error updating header cache:", error);
                }
            }, 0);
            return cachedHeader;
        }

        // If no cache, fetch from Firestore
        const docSnap = await getDoc(ourStoryHeaderDoc);
        let headerData;
        if (docSnap.exists()) {
            headerData = {
                image: docSnap.data().image || "",
                description: docSnap.data().description || "",
            };
        } else {
            headerData = { image: "", description: "" };
        }

        // Cache the fetched data
        const tx = db.transaction(HEADER_STORE, "readwrite");
        const store = tx.objectStore(HEADER_STORE);
        await store.put(headerData, "header");
        await tx.done;
        console.log("Cached header in IndexedDB");

        return headerData;
    } catch (error) {
        console.error("Error fetching Our Story header:", error);
        throw new Error("Failed to fetch header: " + error.message);
    }
};

// Set or update the header picture and description
export const setOurStoryHeader = async (headerData) => {
    try {
        await setDoc(ourStoryHeaderDoc, headerData);
        console.log("Header updated successfully");

        // Update cache
        const db = await initDB();
        const tx = db.transaction(HEADER_STORE, "readwrite");
        const store = tx.objectStore(HEADER_STORE);
        await store.put(headerData, "header");
        await tx.done;
        console.log("Updated header in IndexedDB cache");
    } catch (error) {
        console.error("Error setting Our Story header:", error);
        throw new Error("Failed to set header: " + error.message);
    }
};