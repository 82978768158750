import { firestore } from "../config/firebase";
import { collection, getDocs } from "firebase/firestore";
import { openDB } from "idb";

const giftCollection = collection(firestore, "giftDetails");

// Initialize IndexedDB
const DB_NAME = "GiftDB";
const DB_VERSION = 1;
const GIFT_DETAILS_STORE = "giftDetails";

const initDB = async () => {
    return openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
            // Create object store for gift details
            if (!db.objectStoreNames.contains(GIFT_DETAILS_STORE)) {
                db.createObjectStore(GIFT_DETAILS_STORE, { keyPath: "id" });
            }
        },
    });
};

// Fetch gift details with caching
export const getGiftDetails = async () => {
    try {
        const db = await initDB();

        // Try to get cached data
        const cachedGifts = await db.getAll(GIFT_DETAILS_STORE);
        if (cachedGifts && cachedGifts.length > 0) {
            console.log("Returning cached gift details from IndexedDB");
            // Return cached data immediately
            setTimeout(async () => {
                // Fetch fresh data in the background and update cache
                try {
                    const giftRef = collection(firestore, "giftDetails");
                    const snapshot = await getDocs(giftRef);
                    const freshGifts = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        accountName: doc.data().accountName,
                        accountNumber: doc.data().accountNumber,
                        bankName: doc.data().bankName,
                        currency: doc.data().currency,
                    }));
                    // Update cache
                    const tx = db.transaction(GIFT_DETAILS_STORE, "readwrite");
                    const store = tx.objectStore(GIFT_DETAILS_STORE);
                    await store.clear();
                    freshGifts.forEach((gift) => store.put(gift));
                    await tx.done;
                    console.log("Updated gift details cache in IndexedDB");
                } catch (error) {
                    console.error("Error updating gift details cache:", error);
                }
            }, 0);
            return cachedGifts;
        }

        // If no cache, fetch from Firestore
        const giftRef = collection(firestore, "giftDetails");
        const snapshot = await getDocs(giftRef);
        const gifts = snapshot.docs.map((doc) => ({
            id: doc.id,
            accountName: doc.data().accountName,
            accountNumber: doc.data().accountNumber,
            bankName: doc.data().bankName,
            currency: doc.data().currency,
        }));

        // Cache the fetched data
        const tx = db.transaction(GIFT_DETAILS_STORE, "readwrite");
        const store = tx.objectStore(GIFT_DETAILS_STORE);
        await store.clear();
        gifts.forEach((gift) => store.put(gift));
        await tx.done;
        console.log("Cached gift details in IndexedDB");

        return gifts;
    } catch (error) {
        console.error("Error fetching gift details:", error);
        return [];
    }
};