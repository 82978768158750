import { useEffect, useRef, useState } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Heart, X } from "lucide-react";
import { getOurStoryEvents, getOurStoryHeader } from "../services/OurStoryService";
import "../styles/OurStory.css";

const StoryEvent = ({ event, index, onImageClick }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: "-50px" });
    const controls = useAnimation();

    useEffect(() => {
        if (isInView) {
            controls.start("visible");
        }
    }, [isInView, controls]);

    return (
        <motion.div
            ref={ref}
            className="story-event"
            initial="hidden"
            animate={controls}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
            }}
            style={{ "--event-index": index }}
        >
            <div className="story-content">
                <div className="story-text">
                    <h3 className="story-title">{event.coupleName}</h3>
                </div>
                <img
                    src={event.image}
                    alt={event.coupleName}
                    className="story-image"
                    onClick={() => onImageClick(event.image, event.photoDescription)}
                />
                <div className="story-text">
                    <p className="story-description">{event.photoDescription}</p>
                </div>
            </div>
        </motion.div>
    );
};

const OurStory = () => {
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [header, setHeader] = useState({ image: "", description: "" });
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [modalDescription, setModalDescription] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedEvents = await getOurStoryEvents();
                setEvents(fetchedEvents);

                const headerData = await getOurStoryHeader();
                setHeader(headerData);

                setLoading(false);
            } catch (error) {
                console.error("Failed to load story data:", error);
                setEvents([]);
                setHeader({ image: "", description: "" });
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleImageClick = (image, description) => {
        setModalImage(image);
        setModalDescription(description);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImage("");
        setModalDescription("");
    };

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    return (
        <div className="our-story-container">
            <header className="story-header">
                <button className="back-button" onClick={handleBack}>
                    <ArrowLeft size={24} /> Back
                </button>
                <motion.div initial="hidden" animate="visible" variants={fadeInUp}>
                    <h2>
                        <Heart size={30} className="section-icon" /> Our Story
                    </h2>
                </motion.div>
            </header>

            {header.image && (
                <motion.div
                    className="story-header-content"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                >
                    <img
                        src={header.image}
                        alt="Our Story Header"
                        className="story-header-image"
                        onClick={() => handleImageClick(header.image, header.description)}
                    />
                    {header.description && (
                        <p className="story-header-description">{header.description}</p>
                    )}
                </motion.div>
            )}

            <div className="story-timeline">
                {loading ? (
                    <p className="no-events">Loading story...</p>
                ) : events.length === 0 ? (
                    <p className="no-events">No story found.</p>
                ) : (
                    events.map((event, index) => (
                        <StoryEvent
                            key={event.id}
                            event={event}
                            index={index}
                            onImageClick={handleImageClick}
                        />
                    ))
                )}
            </div>

            {modalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                    <motion.div
                        className="modal-content"
                        onClick={(e) => e.stopPropagation()}
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        transition={{ duration: 0.3 }}
                    >
                        <button className="close-button" onClick={closeModal}>
                            <X size={24} />
                        </button>
                        <img src={modalImage} alt="Modal Image" className="modal-image" />
                        <p className="modal-description">{modalDescription}</p>
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default OurStory;