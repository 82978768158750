import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { splitScreenData } from "../data/splitScreenData";
import { ChevronRight, Heart } from "lucide-react";
import { useLoading } from "../contexts/LoadingContext";
import "../styles/Onboarding.css";

const Onboarding = () => {
    const navigate = useNavigate();
    const { setLoading } = useLoading();
    const [index, setIndex] = useState(0);
    const [animateIcon, setAnimateIcon] = useState(false);

    useEffect(() => {
        const seenOnboarding = localStorage.getItem("seenOnboarding");
        if (seenOnboarding) navigate("/home");
    }, [navigate]);

    const handleNext = () => {
        if (index < splitScreenData.length - 1) {
            setIndex(index + 1);
        }
    };

    const handlePrev = () => {
        if (index > 0) setIndex(index - 1);
    };

    const handleExplore = () => {
        setAnimateIcon(true);
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            localStorage.setItem("seenOnboarding", "true");
            navigate("/home");
        }, 3000);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        trackMouse: true,
    });

    return (
        <div className="onboarding-container" {...swipeHandlers}>
            <AnimatePresence mode="wait">
                <motion.div
                    key={index}
                    className="onboarding-slide"
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                >
                    <h1>{splitScreenData[index].headerText}</h1>
                    <Lottie animationData={splitScreenData[index].lottie} className="onboarding-lottie" />
                    <p>{splitScreenData[index].descriptionText}</p>
                </motion.div>
            </AnimatePresence>

            {index === splitScreenData.length - 1 ? (
                <button className="explore-button" onClick={handleExplore}>
                    Explore Our Story
                    <Heart className={`love-icon ${animateIcon ? "animate" : ""}`} />
                </button>
            ) : (
                <div className="onboarding-bottom">
                    <span className="swipe-hint">Swipe</span>
                    <ChevronRight className="swipe-icon" />
                </div>
            )}
        </div>
    );
};

export default Onboarding;
