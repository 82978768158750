import { useState } from "react";
import { firestore } from "../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import PinataUploader from "../utils/PinataUploader";
import "../styles/UploadImages.css";

const UploadImages = () => {
    const [files, setFiles] = useState([]);
    const [titles, setTitles] = useState({});
    const [descriptions, setDescriptions] = useState({});
    const [name, setName] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length > 0) {
            setFiles(selectedFiles);
            const newTitles = {};
            const newDescriptions = {};
            selectedFiles.forEach((file) => {
                newTitles[file.name] = "";
                newDescriptions[file.name] = "";
            });
            setTitles(newTitles);
            setDescriptions(newDescriptions);
        }
    };

    const handleTitleChange = (fileName, value) => {
        setTitles((prev) => ({ ...prev, [fileName]: value }));
    };

    const handleDescriptionChange = (fileName, value) => {
        setDescriptions((prev) => ({ ...prev, [fileName]: value }));
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleUploadComplete = async (imageData) => {
        try {
            await addDoc(collection(firestore, "homeImages"), {
                url: imageData.url,
                title: imageData.title,
                description: imageData.description,
                Name: imageData.name, // Use the name from imageData
            });
            setSuccess(`Image "${imageData.title}" uploaded successfully!`);
        } catch (err) {
            setError("Failed to save image metadata to Firestore.");
            console.error("Firestore save error:", err);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (files.length === 0) {
            setError("Please select at least one image.");
            return;
        }
        if (!name) {
            setError("Please provide your name.");
            return;
        }

        // Pass files, titles, descriptions, and name to PinataUploader
        document.getElementById("pinata-uploader").uploadFiles(files, titles, descriptions, name);
        setFiles([]);
        setTitles({});
        setDescriptions({});
        setName("");
        e.target.reset();
    };

    return (
        <div className="upload-images">
            <h2>Upload New Image</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Your Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={handleNameChange}
                        placeholder="Enter your name"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="image">Select Images:</label>
                    <input
                        type="file"
                        id="image"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                    />
                </div>
                {files.length > 0 && (
                    <div className="file-details">
                        {files.map((file) => (
                            <div key={file.name} className="file-input-group">
                                <p>{file.name}</p>
                                <div className="form-group">
                                    <label htmlFor={`title-${file.name}`}>Title:</label>
                                    <input
                                        type="text"
                                        id={`title-${file.name}`}
                                        value={titles[file.name] || ""}
                                        onChange={(e) =>
                                            handleTitleChange(file.name, e.target.value)
                                        }
                                        placeholder="Enter image title"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor={`description-${file.name}`}>
                                        Description:
                                    </label>
                                    <textarea
                                        id={`description-${file.name}`}
                                        value={descriptions[file.name] || ""}
                                        onChange={(e) =>
                                            handleDescriptionChange(file.name, e.target.value)
                                        }
                                        placeholder="Enter image description"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
                <button type="submit">Upload Images</button>
            </form>
            <PinataUploader id="pinata-uploader" onUploadComplete={handleUploadComplete} />
        </div>
    );
};

export default UploadImages;