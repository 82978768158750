import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, HelpCircle, MessageCircle, MessageSquare, ChevronDown, ChevronUp, Phone } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { getFaqs } from "../services/FaqService";
import "../styles/Faq.css";

const Faq = () => {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expanded, setExpanded] = useState({}); // Track expanded state for each FAQ
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const faqList = await getFaqs();
                console.log("Fetched FAQs:", faqList); // Debug: Log the fetched FAQs
                setFaqs(faqList || []);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch FAQs:", error);
                setError("Failed to load FAQs. Please try again later.");
                setLoading(false);
            }
        };
        fetchFaqs();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const toggleExpand = (id) => {
        console.log("Toggling FAQ:", id, "Expanded state:", expanded[id]); // Debug: Log toggle action
        setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const openPhone = () => {
        const phoneNumber = "+2349161821626"; // Replace with your phone number
        window.open(`tel:${phoneNumber}`, "_blank");
    };

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const fadeInLeft = {
        hidden: { opacity: 0, x: -20 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    const fadeInRight = {
        hidden: { opacity: 0, x: 20 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    return (
        <div className="faq-page">
            {/* Header Section */}
            <header className="faq-header">
                <button
                    className="back-button"
                    onClick={handleBack}
                    aria-label="Go back to previous page"
                >
                    <ArrowLeft size={24} /> Back
                </button>
                <motion.div initial="hidden" animate="visible" variants={fadeInUp}>
                    <h2>
                        <HelpCircle size={30} className="section-icon" /> Frequently Asked Questions
                    </h2>
                </motion.div>
            </header>

            {/* FAQ List Section */}
            <section className="faq-list-section">
                {loading ? (
                    <motion.div
                        className="loading-spinner"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className="spinner"></div>
                        <p>Loading FAQs...</p>
                    </motion.div>
                ) : error ? (
                    <motion.p
                        className="error-message"
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                    >
                        {error}
                    </motion.p>
                ) : faqs.length === 0 ? (
                    <motion.p
                        className="no-faqs"
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                    >
                        No FAQs available at the moment. Please check back later.
                    </motion.p>
                ) : (
                    <div className="faq-list">
                        {faqs.map((faq) => (
                            <div key={faq.id} className="faq-pair">
                                {/* Question Container (Left) */}
                                <motion.div
                                    className="faq-question"
                                    initial="hidden"
                                    animate="visible"
                                    variants={fadeInLeft}
                                    onClick={() => toggleExpand(faq.id)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter" || e.key === " ") {
                                            e.preventDefault();
                                            toggleExpand(faq.id);
                                        }
                                    }}
                                    role="button"
                                    tabIndex={0}
                                    aria-expanded={!!expanded[faq.id]}
                                    aria-controls={`faq-answer-${faq.id}`}
                                >
                                    <div className="faq-content">
                                        <div className="faq-icon">
                                            <MessageCircle size={24} />
                                        </div>
                                        <div className="faq-text">
                                            <h3>{faq.question}</h3>
                                        </div>
                                        <div className="faq-toggle">
                                            {expanded[faq.id] ? (
                                                <ChevronUp size={20} />
                                            ) : (
                                                <ChevronDown size={20} />
                                            )}
                                        </div>
                                    </div>
                                </motion.div>

                                {/* Answer Container (Right) */}
                                <AnimatePresence>
                                    {expanded[faq.id] && (
                                        <motion.div
                                            id={`faq-answer-${faq.id}`}
                                            className="faq-answer"
                                            initial={{ height: 0, opacity: 0 }}
                                            animate={{ height: "auto", opacity: 1 }}
                                            exit={{ height: 0, opacity: 0 }}
                                            transition={{ duration: 0.3, ease: "easeOut" }}
                                            variants={fadeInRight}
                                        >
                                            <div className="faq-content">
                                                <div className="faq-icon">
                                                    <MessageSquare size={24} />
                                                </div>
                                                <div className="faq-text">
                                                    <p>{faq.answer}</p>
                                                </div>
                                            </div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        ))}
                    </div>
                )}
            </section>

            {/* Contact Section */}
            <motion.section
                className="contact-section"
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
            >
                <p className="contact-text">Still have questions?</p>
                <button onClick={openPhone} className="contact-button">
                    <Phone size={24} />
                    <span>Contact Us</span>
                </button>
            </motion.section>
        </div>
    );
};

export default Faq;