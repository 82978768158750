import { useState } from "react";
import { Image, Settings } from "lucide-react"; // Icons for Upload and Manage
import UploadImages from "../../components/UploadImages";
import ManageImages from "../../components/ManageImages";
import "../../styles/Images.css";

const Images = () => {
    const [activeTab, setActiveTab] = useState("upload"); // Default to UploadImages

    return (
        <div className="admin-images-container">
            <h1>Manage Home Images</h1>
            <div className="tabs">
                <button
                    className={`tab ${activeTab === "upload" ? "active" : ""}`}
                    onClick={() => setActiveTab("upload")}
                >
                    <Image size={20} /> Upload Images
                </button>
                <button
                    className={`tab ${activeTab === "manage" ? "active" : ""}`}
                    onClick={() => setActiveTab("manage")}
                >
                    <Settings size={20} /> Manage Images
                </button>
            </div>
            <div className="tab-content">
                {activeTab === "upload" ? <UploadImages /> : <ManageImages />}
            </div>
        </div>
    );
};

export default Images;