import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../../contexts/AdminContext";
import { Edit, Trash2, PlusCircle, Settings } from "lucide-react";
import { toast } from "react-hot-toast";
import { getFaqs, addFaq, updateFaq, deleteFaq } from "../../services/FaqService";
import "../../styles/AdminFaq.css";

const AdminFaq = () => {
    const { isAdmin, loading } = useAdmin();
    const navigate = useNavigate();
    const [faqs, setFaqs] = useState([]);
    const [formData, setFormData] = useState({ question: "", answer: "" });
    const [editId, setEditId] = useState(null);
    const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
    const [isAdding, setIsAdding] = useState(true);

    // Fetch FAQs on mount and handle redirect if not admin
    useEffect(() => {
        if (!loading && !isAdmin) {
            navigate("/");
            return;
        }

        const fetchFaqs = async () => {
            const faqList = await getFaqs();
            setFaqs(faqList);
        };

        fetchFaqs();
    }, [loading, isAdmin, navigate]); // Dependencies for useEffect

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newFaq = { ...formData };

        try {
            if (editId) {
                await updateFaq(editId, newFaq);
                setFaqs(faqs.map((faq) =>
                    faq.id === editId ? { ...faq, ...newFaq } : faq
                ));
                toast.success("FAQ updated successfully!");
                setEditId(null);
            } else {
                await addFaq(newFaq);
                const faqList = await getFaqs(); // Refresh list after adding
                setFaqs(faqList);
                toast.success("FAQ added successfully!");
            }
            setFormData({ question: "", answer: "" });
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleEdit = (faq) => {
        setFormData({
            question: faq.question,
            answer: faq.answer,
        });
        setEditId(faq.id);
        setIsAdding(true);
    };

    const handleDelete = async () => {
        try {
            await deleteFaq(deleteModal.id);
            setFaqs(faqs.filter((faq) => faq.id !== deleteModal.id));
            setDeleteModal({ open: false, id: null });
            toast.success("FAQ deleted successfully!");
        } catch (error) {
            toast.error(error.message);
        }
    };

    const openDeleteModal = (id) => {
        setDeleteModal({ open: true, id });
    };

    const closeDeleteModal = () => {
        setDeleteModal({ open: false, id: null });
    };

    // Render nothing while loading or if redirected
    if (loading) {
        return null; // Or a loading spinner
    }

    return (
        <div className="admin-faq">
            <h2>FAQ Management</h2>

            {/* Mode Toggle */}
            <div className="mode-toggle">
                <button
                    className={`toggle-btn ${isAdding ? "active" : ""}`}
                    onClick={() => setIsAdding(true)}
                >
                    <PlusCircle size={20} /> Add FAQ
                </button>
                <button
                    className={`toggle-btn ${!isAdding ? "active" : ""}`}
                    onClick={() => setIsAdding(false)}
                >
                    <Settings size={20} /> Manage FAQs
                </button>
            </div>

            {/* Add/Edit Form */}
            {isAdding && (
                <form className="faq-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="question"
                        value={formData.question}
                        onChange={handleInputChange}
                        placeholder="Question"
                        required
                    />
                    <textarea
                        name="answer"
                        value={formData.answer}
                        onChange={handleInputChange}
                        placeholder="Answer"
                        required
                        rows="4"
                    />
                    <button type="submit">{editId ? "Update" : "Add"} FAQ</button>
                </form>
            )}

            {/* Manage FAQs List */}
            {!isAdding && (
                <div className="faq-list">
                    {faqs.length === 0 ? (
                        <p>No FAQs added yet.</p>
                    ) : (
                        faqs.map((faq) => (
                            <div key={faq.id} className="faq-item">
                                <div className="faq-info">
                                    <p><strong>Question:</strong> {faq.question}</p>
                                    <p><strong>Answer:</strong> {faq.answer}</p>
                                </div>
                                <div className="faq-actions">
                                    <button onClick={() => handleEdit(faq)}>
                                        <Edit size={20} />
                                    </button>
                                    <button onClick={() => openDeleteModal(faq.id)}>
                                        <Trash2 size={20} />
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {deleteModal.open && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Confirm Deletion</h3>
                        <p>Are you sure you want to delete this FAQ?</p>
                        <div className="modal-buttons">
                            <button className="modal-cancel" onClick={closeDeleteModal}>
                                Cancel
                            </button>
                            <button className="modal-confirm" onClick={handleDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminFaq;