import { firestore } from "../config/firebase";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { openDB } from "idb";

const faqCollection = collection(firestore, "faqs");

// IndexedDB setup
const DB_NAME = "FaqDB";
const DB_VERSION = 1;
const FAQ_STORE = "faqs";

const initDB = async () => {
    return openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(FAQ_STORE)) {
                db.createObjectStore(FAQ_STORE, { keyPath: "id" });
            }
        },
    });
};

// Fetch FAQs with caching
export const getFaqs = async () => {
    try {
        const db = await initDB();
        const cachedFaqs = await db.getAll(FAQ_STORE);
        if (cachedFaqs.length > 0) {
            console.log("Returning cached FAQs");
            setTimeout(async () => {
                try {
                    const snapshot = await getDocs(faqCollection);
                    const freshFaqs = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        question: doc.data().question,
                        answer: doc.data().answer,
                    }));
                    const tx = db.transaction(FAQ_STORE, "readwrite");
                    const store = tx.objectStore(FAQ_STORE);
                    await store.clear();
                    freshFaqs.forEach((faq) => store.put(faq));
                    await tx.done;
                    console.log("Updated FAQs cache");
                } catch (error) {
                    console.error("Error updating FAQs cache:", error);
                }
            }, 0);
            return cachedFaqs;
        }

        const snapshot = await getDocs(faqCollection);
        const faqs = snapshot.docs.map((doc) => ({
            id: doc.id,
            question: doc.data().question,
            answer: doc.data().answer,
        }));

        const tx = db.transaction(FAQ_STORE, "readwrite");
        const store = tx.objectStore(FAQ_STORE);
        await store.clear();
        faqs.forEach((faq) => store.put(faq));
        await tx.done;
        console.log("Cached FAQs");

        return faqs;
    } catch (error) {
        console.error("Error fetching FAQs:", error);
        return [];
    }
};

// Add FAQ and update cache
export const addFaq = async (faq) => {
    try {
        const docRef = await addDoc(faqCollection, faq);
        const newFaq = { ...faq, id: docRef.id };
        const db = await initDB();
        const tx = db.transaction(FAQ_STORE, "readwrite");
        await tx.objectStore(FAQ_STORE).put(newFaq);
        await tx.done;
        console.log("Added FAQ to cache");
    } catch (error) {
        throw new Error("Failed to add FAQ: " + error.message);
    }
};

// Update FAQ and cache
export const updateFaq = async (id, faq) => {
    try {
        const faqDoc = doc(firestore, "faqs", id);
        await updateDoc(faqDoc, faq);
        const updatedFaq = { ...faq, id };
        const db = await initDB();
        const tx = db.transaction(FAQ_STORE, "readwrite");
        await tx.objectStore(FAQ_STORE).put(updatedFaq);
        await tx.done;
        console.log("Updated FAQ in cache");
    } catch (error) {
        throw new Error("Failed to update FAQ: " + error.message);
    }
};

// Delete FAQ and update cache
export const deleteFaq = async (id) => {
    try {
        const faqDoc = doc(firestore, "faqs", id);
        await deleteDoc(faqDoc);
        const db = await initDB();
        const tx = db.transaction(FAQ_STORE, "readwrite");
        await tx.objectStore(FAQ_STORE).delete(id);
        await tx.done;
        console.log("Deleted FAQ from cache");
    } catch (error) {
        throw new Error("Failed to delete FAQ: " + error.message);
    }
};