import welcomeJson from "../assets/lottie/welcome.json";
import featuresJson from "../assets/lottie/features.json";
import getStartedJson from "../assets/lottie/get-started2.json";

export const splitScreenData = [
  {
    id: 1,
    headerText: "Our Love Story Begins",
    descriptionText: "Welcome to our little corner of joy—where we share the magic of our journey with you.",
    lottie: welcomeJson,
  },
  {
    id: 2,
    headerText: "Moments That Made Us",
    descriptionText: "From our first glance to this beautiful day, explore the chapters of our forever.",
    lottie: featuresJson,
  },
  {
    id: 3,
    headerText: "Join Our Celebration",
    descriptionText: "We’re saying ‘I do’—and we’d love for you to be part of our happily ever after!",
    lottie: getStartedJson,
  },
];
