import { useState } from "react";
import { UploadCloud, Loader } from "lucide-react";
import { motion } from "framer-motion";
import "../styles/UploadWidget.css";

const UploadWidget = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [countdown, setCountdown] = useState(3);

    const uploadUrl = "https://photos.google.com/share/AF1QipPodniN0YYuD4CP3UbGAXdR0Do5ylmT-h-agONiX-2omZGzePAK3lZYF97Ldgo3mA?key=RHl5UDlRSDNGR1ZJUGpHbWlWTXV4RHdaVWdSak9n";

    const handleWidgetClick = () => {
        setIsModalOpen(true);
        setCountdown(3);

        let timer = 3;
        const interval = setInterval(() => {
            timer -= 1;
            setCountdown(timer);

            if (timer === 0) {
                clearInterval(interval);
            }
        }, 1000);
    };

    const handleManualRedirect = () => {
        setIsModalOpen(false); // Close modal
    };

    return (
        <div className="upload-widget-container">
            <motion.button
                className="upload-widget"
                onClick={handleWidgetClick}
                initial="hidden"
                animate="visible"
                variants={{
                    hidden: { opacity: 0, scale: 0.95 },
                    visible: { opacity: 1, scale: 1, transition: { duration: 0.5, ease: "easeOut" } }
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                <UploadCloud size={24} className="upload-icon" />
                <span>Upload Your POV Of Our Day</span>
            </motion.button>

            {isModalOpen && (
                <div className="upload-modal-overlay">
                    <motion.div
                        className="upload-modal"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4, ease: "easeOut" }}
                    >
                        <h3>Heading to Upload Area</h3>
                        <p>You’re being taken to an external site to upload your POV pictures.</p>
                        {countdown > 0 ? (
                            <div className="countdown">
                                <Loader size={24} className="loader-icon" />
                                <span>Generating Upload Link {countdown}...</span>
                            </div>
                        ) : (
                            <div className="redirect-action">
                                <a
                                    href={uploadUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="manual-redirect-button"
                                    onClick={handleManualRedirect}
                                >
                                    <UploadCloud size={20} className="upload-icon" />
                                    Go to Upload
                                </a>
                            </div>
                        )}
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default UploadWidget;
