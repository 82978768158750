import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {  Trash2 } from "lucide-react";
import { getRSVP, deleteRSVP } from "../services/AdminOurDayService";
import "../styles/AdminRSVP.css";

const AdminRSVP = () => {
    const [rsvpResponses, setRSVPResponses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRSVP = async () => {
            try {
                const rsvpData = await getRSVP();
                setRSVPResponses(rsvpData || []);
                setLoading(false);
            } catch (error) {
                console.error("Failed to load RSVP data:", error);
                setLoading(false);
            }
        };
        fetchRSVP();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this RSVP response?")) {
            try {
                await deleteRSVP(id);
                setRSVPResponses((prev) => prev.filter((rsvp) => rsvp.id !== id));
            } catch (error) {
                console.error("Error deleting RSVP:", error);
                alert("Failed to delete RSVP. Please try again.");
            }
        }
    };

    const fadeInUp = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    return (
        <motion.div
            className="admin-rsvp"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
        >
            <h3>RSVP Submissions</h3>

            {loading ? (
                <p className="no-data">Loading...</p>
            ) : rsvpResponses.length > 0 ? (
                <div className="rsvp-list">
                    {rsvpResponses.map((rsvp) => (
                        <div key={rsvp.id} className="rsvp-item">
                            <div className="rsvp-info">
                                <h4>{rsvp.name}</h4>
                                <p><strong>Email:</strong> {rsvp.email}</p>
                                {rsvp.message && (
                                    <p><strong>Message:</strong> {rsvp.message}</p>
                                )}
                                <p><strong>Submitted:</strong> {new Date(rsvp.timestamp).toLocaleString()}</p>
                            </div>
                            <div className="rsvp-actions">
                                <button
                                    onClick={() => handleDelete(rsvp.id)}
                                    className="delete-button"
                                    title="Delete"
                                >
                                    <Trash2 size={20} />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="no-data">No RSVP submissions yet.</p>
            )}
        </motion.div>
    );
};

export default AdminRSVP;