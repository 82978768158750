import React from "react";
import { Link } from "react-router-dom";
import { PaintBucket, Image, Gift, Calendar, MessageSquare, Book, Users , Shield 
} from "lucide-react"; // Import Lucide icons
import "../../styles/AdminDashboard.css"; // Add some styling

const AdminDashboard = () => {
    const sections = [
        { title: "Theme Settings", icon: <PaintBucket />, path: "/admin/theme", description: "Customize the app's colors and styles dynamically." },
        { title: "Manage Images", icon: <Image />, path: "/admin/images", description: "Upload and organize photos used in the app." },
        { title: "Gifts", icon: <Gift />, path: "/admin/admingift", description: "Manage the gift registry and user contributions." },
        { title: "Our Day", icon: <Calendar />, path: "/admin/ourday", description: "Edit and update the wedding schedule." },
        { title: "Wishes", icon: <MessageSquare />, path: "/admin/wishes", description: "View and moderate messages from guests." },
        { title: "FAQs", icon: <Book />, path: "/admin/faq", description: "Edit frequently asked questions and answers." },
        { title: "Our Story", icon: <Users />, path: "/admin/ourstory", description: "Tell the story of the couple and update content." },
        { title: "Photo Gallery", icon: <Image  />, path: "/admin/photos", description: "Upload and organize event photos for guests." },
        { title: "Security", icon: <Shield />, path: "/admin/security", description: "Manage admin authentication and access control." },
    ];

    return (
        <div className="admin-dashboard">
            <h1>Admin Dashboard</h1>
            <p>Manage and customize the app effortlessly.</p>
            
            <div className="dashboard-grid">
                {sections.map((section, index) => (
                    <Link to={section.path} key={index} className="dashboard-card">
                        <div className="icon">{section.icon}</div>
                        <h2>{section.title}</h2>
                        <p>{section.description}</p>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default AdminDashboard;
