import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useAdmin } from "../../contexts/AdminContext";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Mail, Lock, LogIn, LoaderCircle } from "lucide-react";
import "../../styles/AdminLogin.css";

const AdminLogin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const { login } = useAuth();
    const { isAdmin } = useAdmin();
    const navigate = useNavigate();

    // If user is already logged in and is an admin, redirect them
    if (isAdmin) {
        navigate("/admin/dashboard");
        return null;
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await login(email, password);
            toast.success("Logged in successfully!");
            navigate("/admin/dashboard");
        } catch (error) {
            toast.error("Invalid credentials or unauthorized.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="admin-login">
            <div className="login-box">
                <h2>Admin Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="input-group">
                        <Mail className="icon" />
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <Lock className="icon" />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? (
                            <LoaderCircle className="loading-icon spin" size={18} />
                        ) : (
                            <LogIn className="icon" size={18} />
                        )}
                        <span>{loading ? "Logging in..." : "Login"}</span>
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AdminLogin;
