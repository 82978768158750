// src/services/ThemeService.js
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";

class ThemeService {
  // Constructor removed as it was empty and unnecessary

  // Fetch theme data directly from Firestore
  async fetchThemeData() {
    try {
      const docRef = doc(firestore, "settings", "theme");
      const docSnap = await getDoc(docRef);
      
      const defaultData = {
        selectedTheme: 'default',
        colors: {
          primary: "#6B456A",
          secondary: "#E8B5C4",
          background: "#FDF8F5",
          text: "#2F2A3D",
          accent: "#CDA875"
        },
        customThemes: {}
      };

      const data = docSnap.exists() ? docSnap.data() : defaultData;
      return data;
    } catch (error) {
      console.error("Error fetching theme data from Firestore:", error);
      throw error;
    }
  }

  // Update theme data in Firestore
  async updateThemeData(selectedTheme, colors, customThemes) {
    const data = {
      selectedTheme,
      colors,
      customThemes
    };

    try {
      await setDoc(doc(firestore, "settings", "theme"), data);
      return data;
    } catch (error) {
      console.error("Error updating theme data in Firestore:", error);
      throw error;
    }
  }

  // Add custom theme
  async addCustomTheme(themeName, customColors) {
    const currentData = await this.fetchThemeData();
    const customThemeName = `custom-${themeName.toLowerCase().replace(/\s+/g, "-")}`;
    
    const updatedCustomThemes = {
      ...currentData.customThemes,
      [customThemeName]: customColors
    };

    return this.updateThemeData(
      customThemeName,
      customColors,
      updatedCustomThemes
    );
  }

  // Get all themes (predefined + custom)
  getAllThemes(predefinedThemes) {
    return this.fetchThemeData().then(data => ({
      ...predefinedThemes,
      ...data.customThemes
    }));
  }
}

const themeService = new ThemeService();
export default themeService;