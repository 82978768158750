import { Link } from "react-router-dom";
import { Menu, X, LayoutDashboard, Palette, Image, CakeSlice, BookOpen, LogOut, HelpCircle, Gift } from "lucide-react";
import { useAdmin } from "../contexts/AdminContext"; // Adjust path as needed
import "../styles/AdminSidebar.css";

const AdminSidebar = ({ isOpen, onToggle }) => {
    const { logout } = useAdmin();

    const handleLogout = async () => {
        await logout();
        onToggle(); // Close sidebar after logout
    };

    return (
        <>
            {/* Mobile Hamburger Button - Outside Sidebar */}
            <button className="menu-toggle" onClick={onToggle}>
                {isOpen ? <X size={28} /> : <Menu size={28} />}
            </button>

            {/* Sidebar */}
            <div className={`sidebar ${isOpen ? "open" : ""}`}>
                <h2 className="logo">Admin</h2>

                <ul className="nav-links">
                    <li onClick={onToggle}>
                        <Link to="/admin/dashboard">
                            <LayoutDashboard size={20} /> Dashboard
                        </Link>
                    </li>
                    <li onClick={onToggle}>
                        <Link to="/admin/theme">
                            <Palette size={20} /> Theme
                        </Link>
                    </li>
                    <li onClick={onToggle}>
                        <Link to="/admin/images">
                            <Image size={20} /> Home Images
                        </Link>
                    </li>
                    <li onClick={onToggle}>
                        <Link to="/admin/wishes">
                            <CakeSlice size={20} /> Wishes
                        </Link>
                    </li>
                    <li onClick={onToggle}>
                        <Link to="/admin/photos">
                            <Image size={20} /> Photos & Videos
                        </Link>
                    </li>
                    <li onClick={onToggle}>
                        <Link to="/admin/ourstory">
                            <BookOpen size={20} /> Our Story
                        </Link>
                    </li>
                    <li onClick={onToggle}>
                        <Link to="/admin/ourday">
                            <BookOpen size={20} /> Our Day
                        </Link>
                    </li>
                    <li onClick={onToggle}>
                        <Link to="/admin/faq">
                            <HelpCircle size={20} /> FAQ
                        </Link>
                    </li>
                    <li onClick={onToggle}>
                        <Link to="/admin/admingift">
                            <Gift size={20} /> Gift
                        </Link>
                    </li>
                </ul>

                {/* Logout Button */}
                <button className="logout" onClick={handleLogout}>
                    <LogOut size={20} /> Logout
                </button>
            </div>
        </>
    );
};

export default AdminSidebar;